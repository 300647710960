import { Logo, ScreenReaderText } from "@enzymefinance/ui";
import classNames from "classnames";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ConnectionAvatar } from "components/connection/ConnectionAvatar.js";
import { ErrorBoundary } from "components/error/ErrorBoundary";
import { Navbar } from "components/layout/Navbar";
import { CurrencyMenu } from "components/menu/CurrencyMenu";
import { DarkModeSwitch } from "components/menu/DarkModeSwitch";
import { DeploymentMenu } from "components/menu/DeploymentMenu";
import { MobileMenu } from "components/menu/MobileMenu";
import { NavbarMenu } from "components/menu/NavbarMenu";
import { Sidebar } from "components/menu/Sidebar";
import { UserMenu } from "components/menu/UserMenu";
import { InlineLink } from "components/routing/Link";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";

import { useDarkLightMode } from "components/providers/DarkLightModeProvider";
import { isWithinIframe } from "utils/iframe";

interface AppLayoutProps {
  className?: string;
  sidebar?: boolean;
  hasPadding?: boolean;
  isLgFullPage?: boolean;
}

export function AppLayout({ className, sidebar = true, hasPadding = true, isLgFullPage = false }: AppLayoutProps) {
  const isFullPage = !sidebar;
  const classes = classNames("flex flex-col min-h-screen min-w-[320px] bg-base-100", className);
  const containerClasses = classNames("flex-1 flex flex-col items-stretch mx-auto w-full", {
    "lg:grid lg:grid-cols-24 lg:gap-4": !isFullPage,
    "sm:px-6 lg:px-8": hasPadding,
    "lg:max-w-screen-2xl": !isLgFullPage,
  });
  const contentClasses = classNames("lg:relative flex flex-col flex-1 space-y-8", {
    "lg:col-span-17 xl:col-span-19": !isFullPage,
    "py-8 lg:py-4": hasPadding,
  });
  const isInIframe = useMemo(() => isWithinIframe(), [isWithinIframe]);

  const { isDarkModeOn } = useDarkLightMode();

  return (
    <div className={classes}>
      <ErrorBoundary>
        <Navbar className="sticky top-0">
          <div className="grid-cols-24 relative grid gap-1 py-3 lg:gap-4 lg:py-4">
            <div className="col-span-4 flex items-center lg:col-span-6">
              <InlineLink
                className="inline-flex flex-none items-center justify-center"
                role="banner"
                rounded="full"
                title="Enzyme — On-chain Asset Management"
                to="/discover"
              >
                <Logo
                  aria-hidden={true}
                  size={9}
                  className="rounded-full"
                  appearance={isDarkModeOn ? "circle-with-name" : "purple-circle-with-name"}
                />
                <ScreenReaderText as="h1">Enzyme</ScreenReaderText>
                <ScreenReaderText as="p">On-chain Asset Management</ScreenReaderText>
              </InlineLink>
            </div>
            <div className="col-span-20 lg:col-span-18 flex max-w-full items-center justify-end">
              <NavbarMenu menu={<ConnectionAvatar kind="compact" />}>
                <NavbarMenu.Item hidden={isInIframe}>
                  <DeploymentMenu />
                </NavbarMenu.Item>
                <NavbarMenu.Item>
                  <CurrencyMenu />
                </NavbarMenu.Item>
                <NavbarMenu.Item>
                  <DarkModeSwitch />
                </NavbarMenu.Item>
                <NavbarMenu.Item hidden={isInIframe}>
                  <UserMenu />
                </NavbarMenu.Item>
              </NavbarMenu>
            </div>
          </div>
        </Navbar>

        <main className="relative flex w-full flex-1 flex-col items-stretch">
          <div className={containerClasses}>
            {/* Mobile menu */}
            <MobileMenu />

            {/* Sidebar */}
            {sidebar ? (
              <div className="hidden lg:relative lg:z-10 lg:col-span-7 lg:block xl:col-span-5">
                <Sidebar />
              </div>
            ) : null}

            {/* Page Content */}
            <div className={contentClasses}>
              <LoadingBoundary>
                <Outlet />
              </LoadingBoundary>
            </div>
          </div>
        </main>
      </ErrorBoundary>
    </div>
  );
}
