import { LoadingBoundary } from "components/common/LoadingBoundary";
import { DashboardNavigation } from "components/dashboard/DashboardNavigation";
import { PageLayout } from "components/layout/PageLayout";
import { Outlet } from "react-router-dom";

export function DashboardLayout() {
  return (
    <PageLayout header={<DashboardNavigation />}>
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </PageLayout>
  );
}
