import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ProtocolBanner } from "components/vault/protocols/ProtocolBanner";
import { VaultAaveV3Navigation } from "components/vault/protocols/aave-v3/VaultAaveV3Navigation";
import { Outlet } from "react-router";

export function VaultAaveV3Layout() {
  return (
    <>
      <ProtocolBanner integrationId="aave-v3" />

      <VaultAaveV3Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
