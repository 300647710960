import type { ComponentPropsWithoutRef } from "react";

export type CompassOutlineProps = ComponentPropsWithoutRef<"svg">;

export function CompassOutline(props: CompassOutlineProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9998 12.9804C12.5435 12.9804 12.9802 12.5392 12.9802 12C12.9802 11.4608 12.5435 11.0196 11.9998 11.0196C11.4606 11.0196 11.0194 11.4608 11.0194 12C11.0194 12.5392 11.4606 12.9804 11.9998 12.9804Z"
        stroke="currentColor"
      />
      <path
        d="M12 2C6.47502 2 2 6.47502 2 12C2 17.52 6.47502 22 12 22C17.525 22 22 17.52 22 12C22 6.47502 17.525 2 12 2ZM14.5 13.5237C14.2998 13.954 13.954 14.2998 13.5237 14.5L8.50075 16.8367C7.6494 17.2327 6.7673 16.3506 7.16335 15.4993L9.50002 10.4763C9.70021 10.046 10.046 9.70021 10.4763 9.50002L15.4993 7.16335C16.3506 6.7673 17.2327 7.6494 16.8367 8.50075L14.5 13.5237Z"
        stroke="currentColor"
      />
    </svg>
  );
}
