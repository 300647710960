import { Network } from "@enzymefinance/environment";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { AccountLayout } from "components/layout/AccountLayout";
import { AppLayout } from "components/layout/AppLayout";
import { DashboardLayout } from "components/layout/DashboardLayout";
import { NetworkLayout } from "components/layout/NetworkLayout";
import { SharesWrapperToolLayout } from "components/layout/SharesWrapperToolLayout";
import { VaultAaveV2Layout } from "components/layout/VaultAaveV2Layout";
import { VaultAaveV3Layout } from "components/layout/VaultAaveV3Layout";
import { VaultCompoundV2Layout } from "components/layout/VaultCompoundV2Layout";
import { VaultPublicLayout } from "components/layout/VaultPublicLayout";
import { VaultSettingsLayout } from "components/layout/VaultSettingsLayout";
import { VaultSettingsReconfigureLayout } from "components/layout/VaultSettingsReconfigureLayout";
import { VaultSettingsUpgradeLayout } from "components/layout/VaultSettingsUpgradeLayout";
import { useNetwork } from "components/providers/NetworkProvider";
import { AnonymousRoute } from "components/routing/AnonymousRoute";
import { AuthenticatedRoute } from "components/routing/AuthenticatedRoute";
import { Routes } from "components/routing/Routes";
import { WhiteLabelById } from "components/whiteLabel/WhiteLabelById";
import { WhiteLabelLayout } from "components/whiteLabel/layout/WhiteLabelLayout";
import { WhiteLabelVaultLayout } from "components/whiteLabel/layout/WhiteLabelVaultLayout";
import WhiteLabelHomeRoute from "components/whiteLabel/routes/WhiteLabelHomeRoute";
import { Route } from "react-router-dom";
import { useArbitraryLoanAvailable } from "utils/hooks/useArbitraryLoanAvailable";
import { isWithinIframe } from "utils/iframe";
import { lazy } from "utils/lazy";

import { debug } from "utils/config";
import { RedemptionQueueToolLayout } from "../layout/RedemptionQueueToolLayout";
import { NavigateWithParams } from "../routing/NavigateWithParams";
import { ScrollToTop } from "../routing/ScrollToTop";

// Discover
const DiscoverRoute = lazy(() => import("routes/discover/DiscoverRoute"));
const DiscoverVaultsRoute = lazy(async () => import("routes/discover/DiscoverVaultsRoute"));
const DiscoverAssetsRoute = lazy(async () => import("routes/discover/DiscoverAssetsRoute"));
const DiscoverIntegrationsRoute = lazy(async () => import("routes/discover/DiscoverIntegrationsRoute"));

// Network
const NetworkInformationRoute = lazy(async () => import("routes/network/NetworkInformationRoute"));
const NetworkActivityRoute = lazy(async () => import("routes/network/NetworkActivityRoute"));

// Tools
const ArbitraryLoanBorrowerRoute = lazy(async () => import("routes/tools/ArbitraryLoanBorrowerRoute"));
const AddressListsRoute = lazy(async () => import("routes/tools/AddressListsRoute"));
const CreateZeroExOrderRoute = lazy(async () => import("routes/tools/CreateZeroExOrderRoute"));
const SharesSplitterRoute = lazy(async () => import("routes/tools/SharesSplitterRoute"));
const SharesWrapperDepositsRoute = lazy(async () => import("routes/tools/sharesWrapper/SharesWrapperDepositsRoute"));
const SharesWrapperTransfersRoute = lazy(async () => import("routes/tools/sharesWrapper/SharesWrapperTransfersRoute"));
const SharesWrapperManagersRoute = lazy(async () => import("routes/tools/sharesWrapper/SharesWrapperManagersRoute"));
const SharesWrapperRedemptionsRoute = lazy(
  async () => import("routes/tools/sharesWrapper/SharesWrapperRedemptionsRoute"),
);
const SharesWrapperDepositorsRoute = lazy(
  async () => import("routes/tools/sharesWrapper/SharesWrapperDepositorsRoute"),
);
const SharesWrapperConfigurationRoute = lazy(
  async () => import("routes/tools/sharesWrapper/SharesWrapperConfigurationRoute"),
);

const RedemptionQueueManagersRoute = lazy(
  async () => import("routes/tools/redemptionQueue/RedemptionQueueManagersRoute"),
);
const RedemptionQueueRequestsRoute = lazy(
  async () => import("routes/tools/redemptionQueue/RedemptionQueueRequestsRoute"),
);
const RedemptionQueueConfigurationRoute = lazy(
  async () => import("routes/tools/redemptionQueue/RedemptionQueueConfigurationRoute"),
);

// Vault - Public View
const VaultCreateRoute = lazy(async () => import("routes/vault/VaultCreateRoute"));
const VaultCreateNewRoute = lazy(async () => import("routes/vault/VaultCreateNewRoute"));
const VaultOverviewRoute = lazy(async () => import("routes/vault/VaultOverviewRouteApp"));
const VaultPortfolioRoute = lazy(async () => import("routes/vault/VaultPortfolioRoute"));
const VaultFinancialsRoute = lazy(async () => import("routes/vault/VaultFinancialsRoute"));
const VaultFeesRoute = lazy(async () => import("routes/vault/VaultFeesRoute"));
const VaultPoliciesRoute = lazy(async () => import("routes/vault/VaultPoliciesRoute"));
const VaultDepositorsRoute = lazy(async () => import("routes/vault/VaultDepositorsRoute"));
const VaultActivityRoute = lazy(async () => import("routes/vault/VaultActivityRoute"));

// Vault - Public View (without link in menu)
const VaultClaimOwnershipRoute = lazy(async () => import("routes/vault/VaultClaimOwnershipRoute"));
const VaultCompareRoute = lazy(async () => import("routes/vault/VaultCompareRoute"));

// Vault - Depositor View
const VaultMyDepositRoute = lazy(async () => import("routes/vault/VaultMyDepositRoute"));
const VaultDivaStakingMigrateRoute = lazy(async () => import("routes/vault/VaultDivaStakingMigrateRoute"));
const VaultDivaStakingRewardsRoute = lazy(async () => import("routes/vault/VaultDivaStakingRewardsRoute"));

// Vault - Manager View
const VaultProtocolsRoute = lazy(async () => import("routes/vault/VaultProtocolsRoute"));
const VaultManagerLayout = lazy(async () => import("components/layout/VaultManagerLayout"));

// Vault - Manager View = DeFi Protocols
const VaultUniswapV3Route = lazy(async () => import("routes/vault/VaultUniswapV3Route"));
const VaultUniswapV3NftRoute = lazy(async () => import("routes/vault/VaultUniswapV3NftRoute"));
const VaultUniswapV3NewPositionRoute = lazy(async () => import("routes/vault/VaultUniswapV3NewPositionRoute"));
const VaultTradeRoute = lazy(async () => import("routes/vault/VaultTradeRoute"));
const VaultBasketTradeRoute = lazy(async () => import("routes/vault/VaultBasketTradeRoute"));
const VaultYearnRoute = lazy(async () => import("routes/vault/VaultYearnRoute"));
const VaultAaveV2BorrowingRoute = lazy(async () => import("routes/vault/aave-v2/VaultAaveV2BorrowingRoute"));
const VaultAaveV2LendingRoute = lazy(async () => import("routes/vault/aave-v2/VaultAaveV2LendingRoute"));
const VaultAaveV3LendingRoute = lazy(async () => import("routes/vault/aave-v3/VaultAaveV3LendingRoute"));
const VaultAaveV3BorrowingRoute = lazy(async () => import("routes/vault/aave-v3/VaultAaveV3BorrowingRoute"));
const VaultDelegateVotesRoute = lazy(async () => import("routes/vault/VaultDelegateVotesRoute"));
const VaultCompoundV2BorrowingRoute = lazy(
  async () => import("routes/vault/compound-v2/VaultCompoundV2BorrowingRoute"),
);
const VaultCompoundV2LendingRoute = lazy(async () => import("routes/vault/compound-v2/VaultCompoundV2LendingRoute"));
const VaultUniswapV2Route = lazy(async () => import("routes/vault/VaultUniswapV2Route"));
const VaultConvexRoute = lazy(async () => import("routes/vault/VaultConvexRoute"));
const VaultCurveRoute = lazy(async () => import("routes/vault/VaultCurveRoute"));
const VaultGMXV2Route = lazy(async () => import("routes/vault/VaultGMXV2Route"));
const VaultBalancerRoute = lazy(async () => import("routes/vault/VaultBalancerRoute"));
const VaultMapleRoute = lazy(async () => import("routes/vault/VaultMapleRoute"));
const VaultPendleV2Route = lazy(async () => import("routes/vault/VaultPendleV2Route"));
const VaultTheGraphRoute = lazy(async () => import("routes/vault/VaultTheGraphRoute"));
const VaultLiquityRoute = lazy(async () => import("routes/vault/VaultLiquityRoute"));
const VaultKilnRoute = lazy(async () => import("routes/vault/VaultKilnRoute"));
const VaultArbitraryLoanRoute = lazy(async () => import("routes/vault/VaultArbitraryLoanRoute"));
const VaultTakeZeroExOrderRoute = lazy(async () => import("routes/vault/VaultTakeZeroExOrderRoute"));
const VaultCompoundV3Route = lazy(async () => import("routes/vault/VaultCompoundV3Route"));
const VaultLidoRoute = lazy(async () => import("routes/vault/VaultLidoRoute"));
const VaultMorphoRoute = lazy(async () => import("routes/vault/VaultMorphoRoute"));
const VaultLMAXRoute = lazy(async () => import("routes/vault/VaultLMAXRoute"));
const VaultStakeWiseRoute = lazy(async () => import("routes/vault/VaultStakeWiseRoute"));
const VaultSparkRoute = lazy(async () => import("routes/vault/VaultSparkRoute"));
const VaultSwellRoute = lazy(async () => import("routes/vault/VaultSwellRoute"));
const VaultVaultCraftRoute = lazy(async () => import("routes/vault/VaultVaultCraftRoute"));

// Vault - Settings
const VaultSettingsProfileRoute = lazy(async () => import("routes/vault/settings/VaultSettingsProfileRoute"));
const VaultSettingsFeesRoute = lazy(async () => import("routes/vault/settings/VaultSettingsFeesRoute"));
const VaultSettingsPoliciesRoute = lazy(async () => import("routes/vault/settings/VaultSettingsPoliciesRoute"));
const VaultSettingsAssetsRoute = lazy(async () => import("routes/vault/settings/VaultSettingsAssetsRoute"));
const VaultSettingsReconfigureRoute = lazy(async () => import("routes/vault/settings/VaultSettingsReconfigureRoute"));
const VaultSettingsReconfigureCreateRoute = lazy(
  async () => import("routes/vault/settings/VaultSettingsReconfigureCreateRoute"),
);
const VaultSettingsReconfigureCreateNewRoute = lazy(
  async () => import("routes/vault/settings/VaultSettingsReconfigureCreateNewRoute"),
);
const VaultSettingsChangeOwnerRoute = lazy(async () => import("routes/vault/settings/VaultSettingsChangeOwnerRoute"));
const VaultSettingsChangeNameRoute = lazy(async () => import("routes/vault/settings/VaultSettingsChangeNameRoute"));

const VaultSettingsUpgradeRoute = lazy(async () => import("routes/vault/settings/VaultSettingsUpgradeRoute"));
const VaultSettingsUpgradeCreateRoute = lazy(
  async () => import("routes/vault/settings/VaultSettingsUpgradeCreateRoute"),
);
const VaultSettingsUpgradeCreateNewRoute = lazy(
  async () => import("routes/vault/settings/VaultSettingsUpgradeCreateNewRoute"),
);

// Authentication
const SignInRoute = lazy(async () => import("routes/SignInRoute"));
const SignOutRoute = lazy(async () => import("routes/SignOutRoute"));
const SignUpRoute = lazy(async () => import("routes/SignUpRoute"));
const ConnectRoute = lazy(async () => import("routes/ConnectRoute"));
const ResetPasswordRoute = lazy(async () => import("routes/ResetPasswordRoute"));
const UpdateEmailRoute = lazy(async () => import("routes/UpdateEmailRoute"));

// Dashboard
const DashboardDepositsRoute = lazy(async () => import("routes/dashboard/DashboardDepositsRoute"));
const DashboardVaultsRoute = lazy(async () => import("routes/dashboard/DashboardVaultsRoute"));
const DashboardActivityRoute = lazy(async () => import("routes/dashboard/DashboardActivityRoute"));
const DashboardSignInRoute = lazy(async () => import("routes/dashboard/DashboardSignInRoute"));

// Account
const AccountRoute = lazy(async () => import("routes/account/AccountRoute"));
const AccountSecurityRoute = lazy(async () => import("routes/account/AccountSecurityRoute"));
const AccountApiTokensRoute = lazy(async () => import("routes/account/AccountApiTokensRoute"));

// User Representative Election
const ElectionVotingRoute = lazy(async () => import("routes/election/ElectionVotingRoute"));

// Catch all
const NotFoundRoute = lazy(async () => import("routes/NotFoundRoute"));

export function AppRoutes() {
  const { network } = useNetwork();
  const isInIframe = isWithinIframe();

  const isArbitraryLoanAvailable = useArbitraryLoanAvailable();

  return (
    <LoadingBoundary>
      <ScrollToTop />
      <Routes>
        {/**
         * WHITE LABEL ROUTES
         */}
        <Route path="/whitelabel/:whiteLabelId" element={<WhiteLabelById />}>
          {/* Home */}
          <Route
            index={true}
            element={
              <WhiteLabelLayout appearance="home">
                <WhiteLabelHomeRoute />
              </WhiteLabelLayout>
            }
          />

          {/* Vault */}
          <Route
            path="vault/:id/*"
            element={
              <WhiteLabelLayout>
                <WhiteLabelVaultLayout />
              </WhiteLabelLayout>
            }
          >
            <Route index={true} element={<VaultOverviewRoute />} />
            <Route path="portfolio" element={<VaultPortfolioRoute />} />
            <Route path="financials" element={<VaultFinancialsRoute />} />
            <Route path="fees" element={<VaultFeesRoute />} />
            <Route path="policies" element={<VaultPoliciesRoute />} />
            <Route path="depositors" element={<VaultDepositorsRoute />} />
            <Route path="activity" element={<VaultActivityRoute />} />
          </Route>
        </Route>

        {/**
         * APP ROUTES
         */}
        {/* Pages with the sidebar */}
        <Route path="/" element={<AppLayout />}>
          {/* Home */}
          <Route
            index={true}
            element={
              <AuthenticatedRoute fallback={<NavigateWithParams replace={true} to="/discover" />}>
                <NavigateWithParams replace={true} to="/dashboard/deposits" />
              </AuthenticatedRoute>
            }
          />

          {/* Discover */}
          <Route path="discover" element={<DiscoverRoute />} />
          <Route path="discover/vaults" element={<DiscoverVaultsRoute />} />
          <Route path="discover/assets" element={<DiscoverAssetsRoute />} />
          <Route path="discover/integrations" element={<DiscoverIntegrationsRoute />} />

          {/* Network */}
          <Route path="network" element={<NetworkLayout />}>
            <Route index={true} element={<NavigateWithParams replace={true} to="information" />} />
            <Route path="information" element={<NetworkInformationRoute />} />
            <Route path="activity" element={<NetworkActivityRoute />} />
          </Route>
          {/* Tools */}
          <Route path="tools">
            <Route path="flexible-loan" element={<ArbitraryLoanBorrowerRoute />} />
            <Route path="create-0x-order" element={<CreateZeroExOrderRoute />} />
            <Route path="fee-splitter" element={<SharesSplitterRoute />} />
            <Route path="address-lists" element={<AddressListsRoute />} />

            <Route path="shares-wrapper" element={<SharesWrapperToolLayout />}>
              <Route path="vault/:id/*">
                <Route path="deposits" element={<SharesWrapperDepositsRoute />} />
                <Route path="transfers" element={<SharesWrapperTransfersRoute />} />
                <Route path="redemptions" element={<SharesWrapperRedemptionsRoute />} />
                <Route path="depositors" element={<SharesWrapperDepositorsRoute />} />
                <Route path="managers" element={<SharesWrapperManagersRoute />} />
                <Route path="configuration" element={<SharesWrapperConfigurationRoute />} />
              </Route>
            </Route>

            <Route path="redemption-queue" element={<RedemptionQueueToolLayout />}>
              <Route path="vault/:id/*">
                <Route path="requests" element={<RedemptionQueueRequestsRoute />} />
                <Route path="managers" element={<RedemptionQueueManagersRoute />} />
                <Route path="configuration" element={<RedemptionQueueConfigurationRoute />} />
              </Route>
            </Route>
          </Route>

          {/* Vault - Public View */}
          <Route path="vault/:id/*" element={<VaultPublicLayout />}>
            <Route index={true} element={<VaultOverviewRoute />} />
            {/* Keeping the overview route for backwards compatibility - users might have previously shared their vault with the /overview path */}
            <Route path="overview" element={<NavigateWithParams replace={true} to=".." />} />
            <Route path="portfolio" element={<VaultPortfolioRoute />} />
            <Route path="financials" element={<VaultFinancialsRoute />} />
            <Route path="fees" element={<VaultFeesRoute />} />
            <Route path="policies" element={<VaultPoliciesRoute />} />
            <Route path="depositors" element={<VaultDepositorsRoute />} />
            <Route path="activity" element={<VaultActivityRoute />} />
            <Route path="compare" element={<VaultCompareRoute />} />
            <Route path="claim-ownership" element={<VaultClaimOwnershipRoute />} />
            <Route path="my-deposit" element={<VaultMyDepositRoute />} />
            {network === Network.ETHEREUM ? (
              <>
                <Route path="rewards" element={<VaultDivaStakingRewardsRoute />} />
                {debug ? <Route path="migrate" element={<VaultDivaStakingMigrateRoute />} /> : null}
              </>
            ) : null}
          </Route>

          {/* Vault - Manager View */}
          <Route path="vault/:id/*" element={<VaultManagerLayout />}>
            <Route path="trade" element={<VaultTradeRoute />} />
            <Route path="basket-trade" element={<VaultBasketTradeRoute />} />
            <Route path="protocols">
              <Route index={true} element={<VaultProtocolsRoute />} />
              <Route path="uniswap-v3" element={<VaultUniswapV3Route />} />
              <Route path="uniswap-v3/:nftId" element={<VaultUniswapV3NftRoute />} />
              <Route path="uniswap-v3/new" element={<VaultUniswapV3NewPositionRoute />} />

              {isArbitraryLoanAvailable ? <Route path="flexible-loan" element={<VaultArbitraryLoanRoute />} /> : null}

              <Route path="aave-v2" element={<VaultAaveV2Layout />}>
                <Route index={true} element={<NavigateWithParams replace={true} to="supply" />} />
                <Route path="supply" element={<VaultAaveV2LendingRoute />} />
                <Route path="borrow" element={<VaultAaveV2BorrowingRoute />} />
              </Route>

              <Route path="aave-v3" element={<VaultAaveV3Layout />}>
                <Route index={true} element={<NavigateWithParams replace={true} to="supply" />} />
                <Route path="supply" element={<VaultAaveV3LendingRoute />} />
                <Route path="borrow" element={<VaultAaveV3BorrowingRoute />} />
              </Route>
              <Route path="balancer" element={<VaultBalancerRoute />} />
              <Route path="curve" element={<VaultCurveRoute />} />
              <Route path="compound-v3" element={<VaultCompoundV3Route />} />
              <Route path="convex" element={<VaultConvexRoute />} />
              <Route path="compound-v2" element={<VaultCompoundV2Layout />}>
                <Route index={true} element={<NavigateWithParams replace={true} to="supply" />} />
                <Route path="supply" element={<VaultCompoundV2LendingRoute />} />
                <Route path="borrow" element={<VaultCompoundV2BorrowingRoute />} />
              </Route>
              <Route path="delegate-votes" element={<VaultDelegateVotesRoute />} />
              <Route path="gmx-v2" element={<VaultGMXV2Route />} />
              <Route path="kiln" element={<VaultKilnRoute />} />
              <Route path="lido" element={<VaultLidoRoute />} />
              <Route path="liquity" element={<VaultLiquityRoute />} />
              <Route path="lmax" element={<VaultLMAXRoute />} />
              <Route path="maple" element={<VaultMapleRoute />} />
              <Route path="morpho" element={<VaultMorphoRoute />} />
              <Route path="pendle-v2" element={<VaultPendleV2Route />} />
              <Route path="spark" element={<VaultSparkRoute />} />
              <Route path="stakewise" element={<VaultStakeWiseRoute />} />
              <Route path="swell" element={<VaultSwellRoute />} />
              <Route path="the-graph" element={<VaultTheGraphRoute />} />
              <Route path="uniswap-v2" element={<VaultUniswapV2Route />} />
              <Route path="vaultcraft" element={<VaultVaultCraftRoute />} />
              <Route path="yearn" element={<VaultYearnRoute />} />
            </Route>

            <Route path="take-0x-order" element={<VaultTakeZeroExOrderRoute />} />

            <Route path="settings" element={<VaultSettingsLayout />}>
              {isInIframe ? null : <Route index={true} element={<VaultSettingsProfileRoute />} />}
              <Route path="fees" element={<VaultSettingsFeesRoute />} />
              <Route path="policies" element={<VaultSettingsPoliciesRoute />} />
              <Route
                path={isInIframe ? undefined : "assets"}
                index={isInIframe}
                element={<VaultSettingsAssetsRoute />}
              />
              <Route path="reconfigure" element={<VaultSettingsReconfigureLayout />}>
                <Route index={true} element={<VaultSettingsReconfigureRoute />} />
              </Route>
              <Route path="change-owner" element={<VaultSettingsChangeOwnerRoute />} />
              <Route path="change-name" element={<VaultSettingsChangeNameRoute />} />
              <Route path="upgrade" element={<VaultSettingsUpgradeLayout />}>
                <Route index={true} element={<VaultSettingsUpgradeRoute />} />
              </Route>
            </Route>
          </Route>

          {/* Dashboard */}
          <Route
            path="dashboard"
            element={
              <AuthenticatedRoute fallback={<NavigateWithParams replace={true} to="/dashboard/sign-in" />}>
                <DashboardLayout />
              </AuthenticatedRoute>
            }
          >
            <Route index={true} element={<NavigateWithParams replace={true} to="deposits" />} />
            <Route path="deposits" element={<DashboardDepositsRoute />} />
            <Route path="vaults" element={<DashboardVaultsRoute />} />
            <Route path="activity" element={<DashboardActivityRoute />} />
          </Route>

          <Route path="dashboard/sign-in" element={<DashboardSignInRoute />} />

          {isInIframe ? null : (
            <>
              {/* Account */}
              <Route
                path="account"
                element={
                  <AuthenticatedRoute>
                    <AccountLayout />
                  </AuthenticatedRoute>
                }
              >
                <Route index={true} element={<AccountRoute />} />
                <Route path="security" element={<AccountSecurityRoute />} />
                <Route path="api-tokens" element={<AccountApiTokensRoute />} />
              </Route>

              {/* Authentication */}
              <Route
                path="/update-email"
                element={
                  <AuthenticatedRoute>
                    <UpdateEmailRoute />
                  </AuthenticatedRoute>
                }
              />

              {/* User Representative Election */}
              <Route
                path="/election/:electionId/voting"
                element={
                  <AuthenticatedRoute>
                    <ElectionVotingRoute />
                  </AuthenticatedRoute>
                }
              />
            </>
          )}
        </Route>

        {/* Pages without the sidebar that are always full screen width */}
        <Route path="/" element={<AppLayout sidebar={false} hasPadding={false} isLgFullPage={true} />}>
          <Route
            path="/sign-out"
            element={
              <AuthenticatedRoute fallback={<NavigateWithParams replace={true} to="/" />}>
                <SignOutRoute />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/sign-in"
            element={
              <AnonymousRoute>
                <SignInRoute />
              </AnonymousRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <AnonymousRoute>
                <SignUpRoute />
              </AnonymousRoute>
            }
          />
          <Route
            path="/connect"
            element={
              <AuthenticatedRoute>
                <ConnectRoute />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AnonymousRoute>
                <ResetPasswordRoute />
              </AnonymousRoute>
            }
          />
        </Route>

        {/* Pages without the sidebar */}
        <Route path="/" element={<AppLayout sidebar={false} />}>
          {/* Vault Create */}
          <Route path="vault/create" element={<VaultCreateRoute />} />
          <Route path="vault/create/new" element={<VaultCreateNewRoute />} />

          {/* Vault Reconfigure */}
          <Route path="vault/:id/*" element={<VaultManagerLayout />}>
            <Route path="settings" element={<VaultSettingsLayout />}>
              <Route path="reconfigure" element={<VaultSettingsReconfigureLayout />}>
                <Route path="create" element={<VaultSettingsReconfigureCreateRoute />} />
                <Route path="create/new" element={<VaultSettingsReconfigureCreateNewRoute />} />
              </Route>
              <Route path="upgrade" element={<VaultSettingsUpgradeLayout />}>
                <Route path="create" element={<VaultSettingsUpgradeCreateRoute />} />
                <Route path="create/new" element={<VaultSettingsUpgradeCreateNewRoute />} />
              </Route>
            </Route>
          </Route>

          {/* Catch all */}
          <Route path="*" element={<NotFoundRoute />} />
        </Route>
      </Routes>
    </LoadingBoundary>
  );
}
