import "regenerator-runtime/runtime";

import { App } from "components/app/App";
import { createRoot } from "react-dom/client";
import "rc-slider/assets/index.css";

// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(document.getElementById("root")!);
root.render(<App />);

// NOTE: If you want to debug a particular whitelabel app, you can uncomment the following line.
// root.render(<WhiteLabel subdomain="<WHITELABEL APP NAME>" />);
