declare global {
  interface Window {
    enzymeDebug: (debugEnabled: boolean) => void;
  }
}

export function initializeDebug() {
  if (typeof window !== "undefined") {
    window.enzymeDebug = (debugEnabled) => {
      if (debugEnabled) {
        window.localStorage.setItem("debug", "true");
      } else {
        window.localStorage.removeItem("debug");
      }

      window.location.reload();
    };
  }
}
