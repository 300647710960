import { SharesWrapperToolNavigation } from "components/tools/sharesWrapper/SharesWrapperNavigation";
import type { SharesWrapperFilteredVaultsInterface } from "components/tools/sharesWrapper/SharesWrapperVaultSelection";
import { SharesWrapperVaultSelection } from "components/tools/sharesWrapper/SharesWrapperVaultSelection";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export function SharesWrapperHeader() {
  const { id: vaultId } = useParams<"id">();
  const [sharesWrapper, setSharesWrapper] = useState<SharesWrapperFilteredVaultsInterface | null>(null);

  const isNavigationVisible = useMemo(
    () => Boolean(sharesWrapper?.sharesWrapperId && vaultId),
    [sharesWrapper?.sharesWrapperId, vaultId],
  );

  return (
    <>
      <SharesWrapperVaultSelection onChangeSharesWrapper={setSharesWrapper} />
      {isNavigationVisible ? <SharesWrapperToolNavigation /> : null}
    </>
  );
}
