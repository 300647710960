import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

import { useVault } from "../../VaultProvider";

type VaultSettingsNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function VaultAaveV2NavigationItem(props: VaultSettingsNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}

export function VaultAaveV2Navigation() {
  const { id } = useVault();

  return (
    <>
      <nav aria-label="Vault Aave V2">
        <Navigation direction="horizontal">
          <VaultAaveV2NavigationItem to={`/vault/${id}/protocols/aave-v2/supply`}>Supply</VaultAaveV2NavigationItem>
          <VaultAaveV2NavigationItem to={`/vault/${id}/protocols/aave-v2/borrow`}>Borrow</VaultAaveV2NavigationItem>
        </Navigation>
      </nav>
    </>
  );
}
