import type { Asset } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import dayjs from "dayjs";
import { GatedRedemptionQueueSharesWrapperDepositMode } from "queries/core";
import { isAddressEqual } from "viem";

export enum WindowDurationOption {
  SECONDS = "second",
  MINUTES = "minute",
  HOURS = "hour",
  DAYS = "day",
}

export const windowDurationOptions = [
  {
    value: WindowDurationOption.SECONDS,
    label: "seconds",
  },
  {
    value: WindowDurationOption.MINUTES,
    label: "minutes",
  },
  {
    value: WindowDurationOption.HOURS,
    label: "hours",
  },
  {
    value: WindowDurationOption.DAYS,
    label: "days",
  },
] as const;

export const depositModeOptions = [
  {
    label: "Direct",
    value: GatedRedemptionQueueSharesWrapperDepositMode.DIRECT,
  },
  {
    label: "Request",
    value: GatedRedemptionQueueSharesWrapperDepositMode.REQUEST,
  },
] as const;

export function getTomorrowDefaultDate() {
  return dayjs()
    .add(1, "day")
    .set("hour", 10)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .format("YYYY-MM-DDTHH:mm");
}

export function getDurationValue(duration: string) {
  const durationTime = dayjs.duration(Number(duration), "seconds");

  const seconds = durationTime.asSeconds();
  const minutes = durationTime.asMinutes();
  const hours = durationTime.asHours();
  const days = durationTime.asDays();

  if (Math.round(days) === days) {
    return {
      value: days,
      option: windowDurationOptions[3],
    };
  }
  if (Math.round(hours) === hours) {
    return {
      value: hours,
      option: windowDurationOptions[2],
    };
  }
  if (Math.round(minutes) === minutes) {
    return {
      value: minutes,
      option: windowDurationOptions[1],
    };
  }

  return {
    value: seconds,
    option: windowDurationOptions[0],
  };
}

export function formatShares(firstWindowStart: number) {
  const date = dayjs.unix(firstWindowStart);

  return date.format("YYYY-MM-DD HH:mm");
}

export function calculateDuration(value: number, type: WindowDurationOption) {
  switch (type) {
    case WindowDurationOption.SECONDS:
      return Math.floor(dayjs.duration(value, "seconds").asSeconds());
    case WindowDurationOption.MINUTES:
      return Math.floor(dayjs.duration(value, "minutes").asSeconds());
    case WindowDurationOption.HOURS:
      return Math.floor(dayjs.duration(value, "hours").asSeconds());
    case WindowDurationOption.DAYS:
      return Math.floor(dayjs.duration(value, "days").asSeconds());
  }
}

export function getDefaultRedemptionAsset(id: string | undefined, options: Asset[]): Asset | undefined {
  if (id === undefined) {
    return undefined;
  }

  const address = toAddress(id);

  return options.find(({ id: optionId }) => isAddressEqual(optionId, address));
}
