import { Alert, Button, SidebarNavigation } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { Link } from "components/routing/Link";
import { useWalletClaimsQuery } from "queries/backend";
import { useOwnershipNominationsVaultListQuery } from "queries/core";
import { useMemo } from "react";
import { client } from "utils/backend";

import { Sidebar } from "./Sidebar";

export function AccountNotificationsMenu() {
  const [signerAddress] = useSigner();

  const { data: walletClaimsData } = useWalletClaimsQuery({ client });
  const walletAddresses = useMemo(() => {
    const claimedWalletIds = walletClaimsData?.me?.walletClaims.map((walletClaim) => walletClaim.wallet.id) ?? [];
    const addresses = signerAddress
      ? [...new Set([signerAddress, ...claimedWalletIds])]
      : [...new Set(claimedWalletIds)];

    return addresses;
  }, [walletClaimsData, signerAddress]);

  const { data: vaultOwnershipNominationsData } = useOwnershipNominationsVaultListQuery({
    variables: { ids: walletAddresses },
  });

  const vaultOwnershipNominations: { address: string; vault: { id: string; name: string } }[] = useMemo(() => {
    return (
      vaultOwnershipNominationsData?.accounts.flatMap((account) =>
        account.ownershipNominations.map((nomination) => ({
          address: account.id,
          vault: { id: nomination.id, name: nomination.name },
        })),
      ) ?? []
    );
  }, [vaultOwnershipNominationsData?.accounts]);

  const vaultOwnershipNominationItems = vaultOwnershipNominations.map((nomination) => (
    <div className="px-2" key={`nomination-${nomination.vault.id}`}>
      <Alert appearance="warning" title="Ownership Claim Available">
        <div className="space-y-4">
          <p>
            The vault owner of the vault {nomination.vault.name} has nominated your address to become the vault owner.
          </p>
          <Button as={Link} to={`/vault/${nomination.vault.id}/claim-ownership`}>
            Claim Ownership
          </Button>
        </div>
      </Alert>
    </div>
  ));
  const notificationItems = [...vaultOwnershipNominationItems];

  return notificationItems.length > 0 ? (
    <div className="px-2 pt-4">
      <SidebarNavigation className="-mx-4">
        <Sidebar.Items>{notificationItems.map((item) => item)}</Sidebar.Items>
      </SidebarNavigation>
    </div>
  ) : null;
}
