import type { Asset } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { isAddressEqual } from "viem";

export function getDefaultRedemptionAsset(id: string | undefined, options: Asset[]): Asset | undefined {
  if (id === undefined) {
    return undefined;
  }

  const address = toAddress(id);

  return options.find(({ id: optionId }) => isAddressEqual(optionId, address));
}
