import type { Deployment } from "@enzymefinance/environment";
import { ArrowsRightLeftIcon, PlusIcon } from "@enzymefinance/icons/solid";
import { useToasts } from "@enzymefinance/toast";
import type { ButtonProps } from "@enzymefinance/ui";
import { Button, Divider, Menu, Tooltip, TooltipProvider, sameWidthModifier, usePopper } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink, Link } from "components/routing/Link";
import { VaultLabel } from "components/vault/VaultLabel";
import { useCallback, useMemo } from "react";

export interface Vault {
  id: string;
  name: string;
  depositor: boolean;
  trader: boolean;
  manager: boolean;
}

interface SelectedVaultLinkProps {
  selected: Vault;
  deployment: Deployment;
}

function SelectedVaultLink({ selected, deployment }: SelectedVaultLinkProps) {
  const { manager, trader, depositor, id, name } = { ...selected };
  const { reference } = usePopper();

  const description = useMemo(
    () =>
      [manager ? "Manager" : undefined, trader ? "Trader" : undefined, depositor ? "Depositor" : undefined]
        .filter(isTruthy)
        .join(" • "),
    [depositor, manager, trader],
  );

  return (
    <div className="space-y-2" {...reference}>
      <div className="-mx-2">
        <TooltipProvider>
          <Tooltip.Item>
            <InlineLink
              className="flex min-w-0 flex-1 items-center space-x-2 overflow-hidden px-2 py-1 transition hover:bg-gray-100 dark:hover:bg-gray-700/25"
              rounded="lg"
              to={`/vault/${id}`}
            >
              <VaultLabel id={id} deployment={deployment} name={name} space={3} lines={2} />
            </InlineLink>
          </Tooltip.Item>
          <Tooltip.Panel>{name}</Tooltip.Panel>
        </TooltipProvider>
      </div>
      <Divider>{description}</Divider>
    </div>
  );
}

interface MyVaultsMenuProps {
  selected?: Vault;
  setSelected: (vault: Vault) => void;
  vaults: Vault[];
}

export function MyVaultsMenu({ selected, setSelected, vaults }: MyVaultsMenuProps) {
  const { deployment } = useNetwork();
  const selectableVaults = vaults.filter((vault) => vault.id !== selected?.id);
  const hasSelectableVaults = !!selectableVaults.length;

  return (
    <Menu modifiers={[sameWidthModifier]} placement="bottom">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h4 className="text-heading-content space-x-1 text-xs font-semibold uppercase">
            <span>My {vaults.length === 1 ? "Vault" : "Vaults"}</span>
            {hasSelectableVaults ? (
              <span aria-hidden={true} className="text-base-content text-xs font-semibold uppercase">
                ({vaults.length})
              </span>
            ) : null}
          </h4>
          <div className="flex space-x-1">
            <TooltipProvider>
              <Tooltip.Item>
                <Button as={Link} icon={PlusIcon} appearance="tertiary" size="xs" to="/vault/create">
                  Create Vault
                </Button>
              </Tooltip.Item>
              <Tooltip.Panel>Create a vault</Tooltip.Panel>
            </TooltipProvider>
            {hasSelectableVaults && (
              <TooltipProvider>
                <Tooltip.Item>
                  <Button<ButtonProps> as={Menu.BaseButton} icon={ArrowsRightLeftIcon} appearance="tertiary" size="xs">
                    Switch Vault
                  </Button>
                </Tooltip.Item>
                <Tooltip.Panel>Switch to a different vault</Tooltip.Panel>
              </TooltipProvider>
            )}
          </div>
        </div>
        {selected !== undefined ? <SelectedVaultLink deployment={deployment} selected={selected} /> : null}
        {hasSelectableVaults && (
          <Menu.Items>
            <Menu.Group>
              <p className="px-4 text-sm">Switch to:</p>
            </Menu.Group>
            <Menu.Group className="max-h-[180px] overflow-y-auto lg:max-h-[250px]">
              {selectableVaults.map((vault) => (
                <MyVaultsMenu.Item key={vault.id} setSelected={setSelected} vault={vault} deployment={deployment} />
              ))}
            </Menu.Group>
            <Menu.Group>
              <div className="flex flex-col px-4 py-2">
                <Button as={Link} leadingIcon={PlusIcon} size="sm" to="/vault/create">
                  Create Vault
                </Button>
              </div>
            </Menu.Group>
          </Menu.Items>
        )}
      </div>
    </Menu>
  );
}

interface MyVaultsMenuItemProps {
  setSelected: (vault: Vault) => void;
  vault: Vault;
  deployment: Deployment;
}

function myVaultsMenuItem({ setSelected, vault, deployment }: MyVaultsMenuItemProps) {
  const { id, name } = vault;
  const { toast } = useToasts();

  const handleClick = useCallback(() => {
    setSelected(vault);
    toast("Switched Vault", { description: `Selected vault is now "${name}"`, kind: "info" });
  }, [setSelected, vault, toast, name]);

  return (
    <Menu.Item as={Link} onClick={handleClick} to={`/vault/${id}`}>
      <VaultLabel id={id} deployment={deployment} name={name} space={3} lines={2} />
    </Menu.Item>
  );
}

MyVaultsMenu.Item = myVaultsMenuItem;
