import { ArchiveBoxArrowDownIcon } from "@enzymefinance/icons/solid";

import type { Address } from "viem";
import { Sidebar } from "./Sidebar";

interface MyDepositSidebarProps {
  id: Address;
}

export function MyDepositSidebar({ id }: MyDepositSidebarProps) {
  return (
    <>
      <Sidebar.Item icon={ArchiveBoxArrowDownIcon} to={`/vault/${id}/my-deposit`}>
        My deposit
      </Sidebar.Item>
    </>
  );
}
