import { AccountNavigation } from "components/account/AccountNavigation";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { Outlet } from "react-router-dom";

import { PageLayout } from "./PageLayout";

export function AccountLayout() {
  return (
    <PageLayout header={<AccountNavigation />}>
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </PageLayout>
  );
}
