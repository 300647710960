import type { CheckboxProps } from "@enzymefinance/hook-form";
import { Checkbox } from "@enzymefinance/hook-form";

export function SharesWrapperCheckbox(props: CheckboxProps) {
  return (
    <div className="bg-base-300 text-base-content text-primary-content mx-1 w-full rounded-md p-3.5">
      <Checkbox {...props} />
    </div>
  );
}
