import type { Address } from "@enzymefinance/environment";
import { ArrowDownTrayIcon } from "@enzymefinance/icons/solid";
import { SidebarNavigation, Tooltip, TooltipProvider, useBoolean } from "@enzymefinance/ui";
import { VaultDepositModal } from "components/vault/deposit/VaultDepositModal";
import type { VaultDetailsFragment } from "queries/core";

import type { Vault } from "./MyVaultsMenu";

interface DepositSidebarItemProps {
  selectedVault?: Vault;
  signerAddress?: Address;
  vault: VaultDetailsFragment;
}

export function DepositSidebarItem({ signerAddress, vault }: DepositSidebarItemProps) {
  const [isOpen, open, close] = useBoolean();

  return (
    <>
      <TooltipProvider>
        <Tooltip.Item className="flex flex-col">
          <SidebarNavigation.Item
            as="button"
            className="text-left"
            disabled={!signerAddress}
            icon={ArrowDownTrayIcon}
            onClick={open}
          >
            Deposit
          </SidebarNavigation.Item>
        </Tooltip.Item>
        {signerAddress ? null : (
          <Tooltip.Panel>Connect to the corresponding wallet to be able to deposit.</Tooltip.Panel>
        )}
      </TooltipProvider>

      {signerAddress ? (
        <VaultDepositModal close={close} isOpen={isOpen} vault={vault} signerAddress={signerAddress} />
      ) : null}
    </>
  );
}
