import { RedemptionQueueToolNavigation } from "components/tools/redemptionQueue/RedemptionQueueNavigation";
import type { RedemptionQueueFilteredVaultsInterface } from "components/tools/redemptionQueue/RedemptionQueueVaultSelection";
import { RedemptionQueueVaultSelection } from "components/tools/redemptionQueue/RedemptionQueueVaultSelection";
import { useState } from "react";
import { useParams } from "react-router-dom";

export function RedemptionQueueHeader() {
  const { id: vaultId } = useParams<"id">();
  const [redemptionQueue, setRedemptionQueue] = useState<RedemptionQueueFilteredVaultsInterface | null>(null);

  const isNavigationVisible = Boolean(redemptionQueue?.redemptionQueueId && vaultId);

  return (
    <>
      <RedemptionQueueVaultSelection onChangeRedemptionQueue={setRedemptionQueue} />
      {isNavigationVisible ? <RedemptionQueueToolNavigation /> : null}
    </>
  );
}
