import { TitleProvider } from "components/providers/TitleProvider";
import { useWhiteLabelByIdQuery } from "queries/backend";
import { Outlet, useParams } from "react-router-dom";
import { client } from "utils/backend";

import { WhiteLabelProvider } from "./WhiteLabelProvider";
import { WhiteLabelTheme } from "./WhiteLabelTheme";

export function WhiteLabelById() {
  const { whiteLabelId } = useParams<"whiteLabelId">();
  const whiteLabelByIdQuery = useWhiteLabelByIdQuery({
    client,
    skip: !whiteLabelId,
    variables: whiteLabelId ? { id: whiteLabelId } : undefined,
  });

  return (
    <WhiteLabelProvider
      data={whiteLabelByIdQuery.data?.whiteLabelById ?? undefined}
      error={whiteLabelByIdQuery.error}
      loading={whiteLabelByIdQuery.loading}
      rootPath={`/whitelabel/${whiteLabelId}/`}
    >
      <TitleProvider title={whiteLabelByIdQuery.data?.whiteLabelById?.name}>
        <WhiteLabelTheme>
          <Outlet />
        </WhiteLabelTheme>
      </TitleProvider>
    </WhiteLabelProvider>
  );
}
