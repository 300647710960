import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import { Utils } from "@enzymefinance/sdk";
import type { ButtonProps } from "@enzymefinance/ui";
import { Alert, Button, IconLabel, Menu, useBoolean } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import { RedemptionQueueEnableModal } from "components/tools/redemptionQueue/enable/RedemptionQueueEnableModal";
import { VaultLabel } from "components/vault/VaultLabel";
import type {
  RedemptionQueueDetailsFragment,
  RedemptionQueueManagerInterfaceVaultsQuery,
  VaultListItemFragment,
} from "queries/core";
import { useMyRedemptionQueueVaultsQuery, useRedemptionQueueManagerInterfaceVaultsQuery } from "queries/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRedemptionQueue } from "utils/redemptionQueue";
import { isAddressEqual } from "viem";

export interface RedemptionQueueFilteredVaultsInterface {
  allowedDepositPolicy: boolean;
  redemptionQueueId: Address | null;
  vault: VaultListItemFragment;
}

interface RedemptionQueueManagerType {
  vault: VaultListItemFragment;
  allowedRedeemersForSpecificAssetsPolicies: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  }[];
  singleAssetRedemptionQueues: RedemptionQueueDetailsFragment[];
}

interface RedemptionQueueVaultSelectionProps {
  onChangeRedemptionQueue: (data: RedemptionQueueFilteredVaultsInterface) => void;
}

export function RedemptionQueueVaultSelection({ onChangeRedemptionQueue }: RedemptionQueueVaultSelectionProps) {
  const { id: vaultId } = useParams<"id">();
  const [isOpen, open, close] = useBoolean();
  const { deployment } = useNetwork();
  const navigate = useNavigate();
  const [signerAddress] = useSigner();
  const [currentSignerAddress, setCurrentSignerAddress] = useState<Address | undefined>(undefined);

  const [vaults, setVaults] = useState<RedemptionQueueFilteredVaultsInterface[]>([]);

  const myRedemptionQueueVaultsQuery = useMyRedemptionQueueVaultsQuery({
    variables: signerAddress
      ? {
          accountId: signerAddress,
        }
      : undefined,
  });

  const managedVaults = useMemo(
    () =>
      myRedemptionQueueVaultsQuery.data?.account?.singleAssetRedemptionQueueManagements.map(({ vault }) => vault) ?? [],
    [myRedemptionQueueVaultsQuery.data?.account?.singleAssetRedemptionQueueManagements],
  );

  const ownerships = useMemo(
    () => myRedemptionQueueVaultsQuery.data?.account?.ownerships ?? [],
    [myRedemptionQueueVaultsQuery.data?.account?.ownerships],
  );

  const myVaults = useMemo(
    () => [...new Map([...managedVaults, ...ownerships].map((item) => [item.id, item])).values()],
    [managedVaults, ownerships],
  );

  useRedemptionQueueManagerInterfaceVaultsQuery({
    variables: {
      vaultIds: myVaults.map((vault) => vault.id),
      comptrollerIds: myVaults.map((vault) => vault.comptroller.id),
    },
    skip: !myVaults.length, // Skip the query if ownerships are empty
    onCompleted: (data) => {
      const resp = processManagerInterfaceVaultsData(data);
      setVaults(resp);
      handleVaultSelection(resp);
    },
  });

  const selectVault = useCallback(
    (data: RedemptionQueueFilteredVaultsInterface) => {
      onChangeRedemptionQueue(data);

      if (data.redemptionQueueId) {
        navigate(`vault/${data.vault.id}/requests`);
      } else {
        navigate(`vault/${data.vault.id}`);
      }
    },
    [navigate, onChangeRedemptionQueue],
  );

  const handleVaultSelection = useCallback(
    (data: RedemptionQueueFilteredVaultsInterface[]) => {
      if (data.length > 0 && vaultId) {
        const selectedVault = data.find(({ vault }) => isAddressEqual(toAddress(vault.id), toAddress(vaultId)));

        if (selectedVault) {
          selectVault(selectedVault);
        } else {
          navigate("/tools/redemption-queue");
        }
      }
    },
    [navigate, selectVault, vaultId],
  );

  const filterVaultsWithRedemptionQueue = useCallback((data: RedemptionQueueManagerType) => {
    const { allowedRedeemersForSpecificAssetsPolicies, singleAssetRedemptionQueues, vault } = data;

    if (!allowedRedeemersForSpecificAssetsPolicies[0]) {
      return {
        allowedDepositPolicy: false,
        redemptionQueueId: singleAssetRedemptionQueues[0]?.id ? toAddress(singleAssetRedemptionQueues[0].id) : null,
        vault,
      };
    }

    const redemptionQueue = getRedemptionQueue({
      singleAssetRedemptionQueues,
      allowedRedeemersForSpecificAssetsPolicy: allowedRedeemersForSpecificAssetsPolicies[0],
    });

    return {
      allowedDepositPolicy: !!redemptionQueue,
      redemptionQueueId: singleAssetRedemptionQueues[0]?.id ? toAddress(singleAssetRedemptionQueues[0].id) : null,
      vault,
    };
  }, []);

  const processManagerInterfaceVaultsData = useCallback(
    (data: RedemptionQueueManagerInterfaceVaultsQuery | undefined) =>
      myVaults.map((vault) => {
        const allowedRedeemersForSpecificAssetsPolicies =
          data?.allowedRedeemersForSpecificAssetsPolicies.filter((policy) =>
            Utils.Address.safeSameAddress(policy.comptroller.vault?.id, vault.id),
          ) ?? [];

        const singleAssetRedemptionQueues =
          data?.singleAssetRedemptionQueues.filter((redemptionQueue) =>
            isAddressEqual(toAddress(redemptionQueue.vault.id), toAddress(vault.id)),
          ) ?? [];

        return filterVaultsWithRedemptionQueue({
          allowedRedeemersForSpecificAssetsPolicies,
          singleAssetRedemptionQueues,
          vault,
        });
      }),
    [filterVaultsWithRedemptionQueue, myVaults],
  );

  useEffect(() => {
    if (Boolean(signerAddress) && !currentSignerAddress) {
      setCurrentSignerAddress(signerAddress);
    }

    if (signerAddress && currentSignerAddress && !isAddressEqual(signerAddress, currentSignerAddress)) {
      navigate("/");
    }
  }, [signerAddress, currentSignerAddress, navigate]);

  const selectedVault = useMemo(() => {
    return vaults.find(({ vault }) => Utils.Address.safeSameAddress(vault.id, vaultId));
  }, [vaultId, vaults]);

  const isRedemptionQueueEnabled = useMemo(() => {
    return !!selectedVault?.redemptionQueueId;
  }, [selectedVault]);

  const isAllowedDepositPolicy = useMemo(() => {
    return !!selectedVault?.allowedDepositPolicy;
  }, [selectedVault?.allowedDepositPolicy]);

  if (vaults.length === 0) {
    return <p>No vaults</p>;
  }

  return (
    <>
      <div className="bg-base-200 p-4 sm:rounded-2xl md:flex md:flex-1 md:items-center md:justify-between">
        <Menu>
          <Menu.Button<ButtonProps>
            as={Button}
            className="bg-base-300 block w-full sm:inline-block sm:w-auto"
            appearance="tertiary"
            size="xl"
            trailingIcon={ChevronDownIcon}
          >
            {selectedVault ? (
              <VaultLabel
                id={selectedVault.vault.id}
                deployment={deployment}
                name={selectedVault.vault.name}
                className="w-full"
              />
            ) : (
              <IconLabel aria-hidden={true} size="xl" space={3} title="Select Vault" className="w-full" />
            )}
          </Menu.Button>
          <Menu.Items>
            <Menu.Group>
              {vaults.map(({ vault, redemptionQueueId, allowedDepositPolicy }) => (
                <Menu.Item
                  as="button"
                  key={vault.id}
                  onClick={() =>
                    selectVault({
                      vault,
                      redemptionQueueId,
                      allowedDepositPolicy,
                    })
                  }
                  className="hover:bg-base-100"
                >
                  <VaultLabel id={vault.id} deployment={deployment} name={vault.name} space={3} lines={2} />
                </Menu.Item>
              ))}
            </Menu.Group>
          </Menu.Items>
        </Menu>
        {isRedemptionQueueEnabled ? null : (
          <Button onClick={open} disabled={!selectedVault} className="my-2 block w-full sm:inline-block sm:w-auto">
            Enable redemption queue
          </Button>
        )}
        <RedemptionQueueEnableModal isOpen={isOpen} close={close} />
      </div>
      {!isAllowedDepositPolicy && isRedemptionQueueEnabled ? (
        <Alert appearance="info">
          {`To finish setting up the Redemption Queue for the ${selectedVault?.vault.name}, add the Redemption Queue contract
          address ${selectedVault?.redemptionQueueId} to the policy "Allowed Redeemers For Specific Assets". To change the policy, go`}{" "}
          <InlineLink to={`/vault/${selectedVault?.vault.id}/settings/policies`}>here</InlineLink>
        </Alert>
      ) : null}
    </>
  );
}
