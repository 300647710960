import type { AuthenticationContextValue } from "components/providers/AuthenticationProvider";
import type { ReactNode } from "react";
import { useCallback } from "react";
import type { RouteProps } from "react-router-dom";

import { NavigateWithParams } from "./NavigateWithParams";
import type { ProtectedRouteProps } from "./ProtectedRoute";
import { ProtectedRoute } from "./ProtectedRoute";

export type AnonymousRouteProps = Pick<ProtectedRouteProps, "fallback" | "loading"> &
  RouteProps & {
    children: ReactNode;
  };

export function AnonymousRoute({ fallback = <NavigateWithParams to="/" />, ...props }: AnonymousRouteProps) {
  const condition = useCallback((auth: AuthenticationContextValue) => !auth.authenticated, []);

  return <ProtectedRoute {...props} condition={condition} fallback={fallback} />;
}
