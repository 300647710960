import { PageHeading } from "@enzymefinance/ui";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { Outlet } from "react-router-dom";

export function VaultSettingsReconfigureLayout() {
  return (
    <LoadingBoundary>
      <PageHeading>Reconfigure Your Vault</PageHeading>
      <Outlet />
    </LoadingBoundary>
  );
}
