import { AcademicCapIcon, ArrowTopRightOnSquareIcon } from "@enzymefinance/icons/outline";
import { Card, Image, PageHeading } from "@enzymefinance/ui";
import { integrations } from "@enzymefinance/utils";
import { useMemo } from "react";

import { InlineLink } from "../../routing/Link";

interface ProtocolBannerProps {
  integrationId: string;
}

export function ProtocolBanner({ integrationId }: ProtocolBannerProps) {
  const integration = useMemo(() => integrations.find(({ id }) => id === integrationId), [integrationId]);

  if (!integration) {
    return null;
  }

  return (
    <Card>
      <Card.Content className="space-y-4">
        <div className="flex items-center space-x-4">
          <div className="overflow-hidden rounded-full bg-gray-800 dark:bg-transparent">
            <Image alt={integration.title} height="auto" src={integration.icon} width={60} />
          </div>

          <PageHeading>{integration.title}</PageHeading>
        </div>
        <p className="text-sm">{integration.description}</p>
        <div className="space-x-4">
          {integration.docsUrl ? (
            <InlineLink trailingIcon={AcademicCapIcon} to={integration.docsUrl} appearance="secondary">
              How to use {integration.title} on Enzyme
            </InlineLink>
          ) : null}
          {integration.url ? (
            <InlineLink trailingIcon={ArrowTopRightOnSquareIcon} to={integration.url} appearance="secondary">
              Read more
            </InlineLink>
          ) : null}
        </div>
      </Card.Content>
    </Card>
  );
}
