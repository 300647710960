import type { ApolloError } from "@apollo/client";
import { toAddress } from "@enzymefinance/environment";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import { useWalletClaimsQuery } from "queries/backend";
import type { VaultListItemFragment, VaultListQuery } from "queries/core";
import { useVaultListQuery } from "queries/core";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { client } from "utils/backend";
import { isAddressEqual } from "viem";

import { useAuthentication } from "./AuthenticationProvider";

interface MyVaultsContextValue {
  accounts: VaultListQuery["accounts"];
  assetManagements: VaultListItemFragment[];
  error?: ApolloError;
  ownerships: VaultListItemFragment[];
  vaults: (VaultListItemFragment & { manager: boolean; depositor: boolean; trader: boolean })[];
  deposits: VaultListItemFragment[];
  loading: boolean;
}

const MyVaultsContext = createContext<MyVaultsContextValue>({
  accounts: [],
  assetManagements: [],
  ownerships: [],
  vaults: [],
  deposits: [],
  loading: false,
});

export function useMyVaults() {
  return useContext(MyVaultsContext);
}

interface MyVaultsProps {
  children?: ReactNode;
  filter?: "claimed";
}

export function MyVaultsProvider({ children, filter }: MyVaultsProps) {
  const { authenticated } = useAuthentication();
  const [signerAddress, signerIsLoading] = useSigner();

  const walletClaimsQuery = useWalletClaimsQuery({ client, skip: !authenticated });

  const claimedWalletIds = useMemo(
    () => walletClaimsQuery.data?.me?.walletClaims.map((walletClaim) => walletClaim.wallet.address) ?? [],
    [walletClaimsQuery.data?.me?.walletClaims],
  );

  const ids = useMemo(
    () =>
      signerAddress && !filter ? [...new Set([signerAddress, ...claimedWalletIds])] : [...new Set(claimedWalletIds)],
    [signerAddress, filter, claimedWalletIds],
  );

  const vaultListQuery = useVaultListQuery({
    skip: !ids.length,
    variables: { ids },
  });

  const network = useNetwork();

  useUpdateEffect(() => {
    vaultListQuery.refetch();
  }, [network]);

  const accounts = useMemo<VaultListQuery["accounts"]>(
    () => vaultListQuery.data?.accounts ?? [],
    [vaultListQuery.data?.accounts],
  );
  const ownerships = useMemo(
    () => accounts.reduce<VaultListItemFragment[]>((acc, current) => [...acc, ...current.ownerships], []),
    [accounts],
  );
  const assetManagements = useMemo(
    () => accounts.reduce<VaultListItemFragment[]>((acc, current) => [...acc, ...current.assetManagements], []),
    [accounts],
  );
  const deposits = useMemo(
    () =>
      accounts.reduce<VaultListItemFragment[]>(
        (acc, current) => [...acc, ...current.deposits.map((deposit) => deposit.vault)],
        [],
      ),
    [accounts],
  );
  const vaults = useMemo(
    () =>
      [...ownerships, ...assetManagements, ...deposits]
        // Remove duplicates by id
        .filter(
          (vault, index, list) =>
            index === list.findIndex((item) => isAddressEqual(toAddress(item.id), toAddress(vault.id))),
        )
        .map((vault) => ({
          ...vault,
          depositor: deposits.some((deposit) => isAddressEqual(toAddress(deposit.id), toAddress(vault.id))),
          manager: ownerships.some((ownership) => isAddressEqual(toAddress(ownership.id), toAddress(vault.id))),
          trader: assetManagements.some((management) => isAddressEqual(toAddress(management.id), toAddress(vault.id))),
        }))
        // Sort by alphabet
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
    [deposits, assetManagements, ownerships],
  );

  return (
    <MyVaultsContext.Provider
      value={{
        accounts,
        assetManagements,
        deposits,
        error: walletClaimsQuery.error || vaultListQuery.error,
        loading: walletClaimsQuery.loading || vaultListQuery.loading || signerIsLoading,
        ownerships,
        vaults,
      }}
    >
      {children}
    </MyVaultsContext.Provider>
  );
}
