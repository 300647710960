import { Compass, CompassOutline } from "@enzymefinance/icons";
import {
  BoltIcon as OutlineBoltIcon,
  CircleStackIcon as OutlineCircleStackIcon,
  KeyIcon as OutlineKeyIcon,
} from "@enzymefinance/icons/outline";
import {
  ArrowRightOnRectangleIcon,
  Bars3BottomLeftIcon,
  BoltIcon,
  CircleStackIcon,
  HomeIcon,
  KeyIcon,
} from "@enzymefinance/icons/solid";
import type { NavigationProps, SidebarNavigationItemProps } from "@enzymefinance/ui";
import { Button, Image, MobileMenu as MobileMenuBase, Navigation } from "@enzymefinance/ui";
import etherLayer from "assets/ether-layer.svg";
import { useConnectionModal, useSigner } from "components/connection/Connection.js";
import { useAuthentication } from "components/providers/AuthenticationProvider";
import type { LinkProps } from "components/routing/Link";
import { InlineLink, Link } from "components/routing/Link";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { isWithinIframe } from "utils/iframe";

import { ActionCard } from "./ActionCard";
import { CurrencyMenu } from "./CurrencyMenu";
import { DarkModeSelect } from "./DarkModeSelect";
import { DeploymentMenu } from "./DeploymentMenu";
import { useMobileMenu } from "./MobileMenuProvider";
import { MyVaults } from "./MyVaults";

export function MobileMenu() {
  const location = useLocation();
  const { open } = useConnectionModal();
  const { isOpen, toggle } = useMobileMenu();
  const close = useCallback(() => toggle(false), [toggle]);
  const isInIframe = useMemo(() => isWithinIframe(), [isWithinIframe]);

  useUpdateEffect(() => {
    if (isOpen) {
      toggle(false);
    }
  }, [location.pathname]);

  const { account, authenticated } = useAuthentication();
  const isSignInPage = location.pathname === "/sign-in";
  const isSignUpPage = location.pathname === "/sign-up";
  const displayName = account?.accountProfile?.name ?? undefined;
  const email = account?.email ?? undefined;

  const [signerAddress] = useSigner();
  const connected = !!signerAddress;

  const isMigrationSetupForm = location.pathname.includes("upgrade/create/new");
  const isReconfigurationSetupForm = location.pathname.includes("reconfigure/create/new");

  return (
    <MobileMenuBase
      actions={
        authenticated || isInIframe ? null : (
          <div className="flex flex-col space-y-2 px-2">
            {isSignUpPage ? <p className="text-center text-sm">Already have an account?</p> : null}
            {isSignInPage ? (
              <p className="text-center text-sm">Don&apos;t have an account?</p>
            ) : (
              <Button as={Link} to="/sign-in">
                Sign In
              </Button>
            )}
            {isSignUpPage ? null : (
              <Button as={Link} appearance={isSignInPage ? "primary" : "secondary"} to="/sign-up">
                Sign Up
              </Button>
            )}
          </div>
        )
      }
      isOpen={isOpen}
    >
      {/* User Menu */}
      {authenticated && displayName && email ? (
        <MobileMenu.Group>
          <InlineLink to="/account" onClick={close}>
            <MobileMenu.User displayName={displayName} email={email} />
          </InlineLink>
        </MobileMenu.Group>
      ) : null}

      {/* Network & Currency Menu */}
      {isSignInPage || isSignUpPage ? null : (
        <MobileMenu.Group>
          <div className="space-y-3 px-3">
            {isInIframe ? null : <DeploymentMenu appearance="select" onDeploymentSelect={close} />}
            <CurrencyMenu appearance="select" onCurrencySelect={close} />
            <DarkModeSelect />
          </div>
        </MobileMenu.Group>
      )}

      {/* Main Menu */}
      <MobileMenu.Group>
        <MobileMenu.Items>
          {authenticated ? (
            <MobileMenu.Item icon={HomeIcon} to="/dashboard">
              Dashboard
            </MobileMenu.Item>
          ) : null}
          <MobileMenu.Item icon={location.pathname === "/discover" ? Compass : CompassOutline} to="/discover">
            Discover
          </MobileMenu.Item>
          <MobileMenu.Item
            icon={location.pathname === "/discover/vaults" ? KeyIcon : OutlineKeyIcon}
            to="/discover/vaults"
          >
            Vaults
          </MobileMenu.Item>
          <MobileMenu.Item icon={Bars3BottomLeftIcon} to="/network">
            Network
          </MobileMenu.Item>
          <MobileMenu.Item
            icon={location.pathname === "/discover/assets" ? CircleStackIcon : OutlineCircleStackIcon}
            to="/discover/assets"
          >
            Assets
          </MobileMenu.Item>
          <MobileMenu.Item
            icon={location.pathname === "/discover/integrations" ? BoltIcon : OutlineBoltIcon}
            to="/discover/integrations"
          >
            Integrations
          </MobileMenu.Item>
        </MobileMenu.Items>
      </MobileMenu.Group>

      {/* Vaults Menu */}
      {((connected || authenticated) && location.pathname === "/vault/create") ||
      isSignInPage ||
      isSignUpPage ||
      isMigrationSetupForm ||
      isReconfigurationSetupForm ? null : (
        <MobileMenu.Group>
          {connected || authenticated ? (
            <MyVaults />
          ) : (
            <ActionCard
              description="Discover new possibilities and more features by connecting your wallet."
              image={<Image alt="" height="auto" src={etherLayer} width={96} />}
              appearance="secondary"
              onClick={open}
              role="button"
              tabIndex={0}
              title="Get started by connecting your wallet"
            >
              Connect Wallet
            </ActionCard>
          )}
        </MobileMenu.Group>
      )}

      {authenticated ? (
        <MobileMenu.Group>
          <MobileMenu.Items>
            <MobileMenu.Item icon={ArrowRightOnRectangleIcon} className="g_id_signout" to="/sign-out">
              Sign Out
            </MobileMenu.Item>
          </MobileMenu.Items>
        </MobileMenu.Group>
      ) : null}
    </MobileMenuBase>
  );
}

MobileMenu.Group = MobileMenuBase.Group;

type MobileMenuItemsProps = Omit<NavigationProps, "direction">;

function mobileMenuItems(props: MobileMenuItemsProps) {
  return <Navigation direction="vertical" {...props} />;
}

MobileMenu.Items = mobileMenuItems;

type MobileMenuItemProps = Omit<SidebarNavigationItemProps<LinkProps>, "as" | "isCurrent">;

function mobileMenuItem(props: MobileMenuItemProps) {
  const location = useLocation();
  // Checks for specific route exceptions between a Link's to path and the actual pathname
  const isCurrentToPath = (to: string) =>
    location.pathname.startsWith(props.to.toString()) && props.to.toString() === to;
  const isCurrent =
    location.pathname === props.to.toString() || isCurrentToPath("/dashboard") || isCurrentToPath("/network");

  return <Navigation.Item as={Link} {...props} isCurrent={isCurrent} isSidebar={true} />;
}

MobileMenu.Item = mobileMenuItem;

MobileMenu.User = MobileMenuBase.User;
