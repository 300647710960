import { ArrowRightOnRectangleIcon } from "@enzymefinance/icons/solid";
import type { NavigationItemProps } from "@enzymefinance/ui";
import { Button, Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

export function AccountNavigation() {
  return (
    <nav aria-label="Account Navigation" className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Navigation direction="horizontal">
        <AccountNavigationItem to="/account">My Account</AccountNavigationItem>
        <AccountNavigationItem to="/account/security">Security</AccountNavigationItem>
        <AccountNavigationItem to="/account/api-tokens">API Tokens</AccountNavigationItem>
      </Navigation>
      <div className="hidden md:inline-flex md:items-center">
        <Button<LinkProps>
          as={Link}
          appearance="destructive"
          className="g_id_signout"
          leadingIcon={ArrowRightOnRectangleIcon}
          to="/sign-out"
        >
          Sign Out
        </Button>
      </div>
    </nav>
  );
}

type AccountNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function AccountNavigationItem(props: AccountNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}
