import type { RadioGroupOption, RadioGroupProps as RadioGroupPropsBase } from "@enzymefinance/ui";
import { RadioGroup as RadioGroupBase } from "@enzymefinance/ui";
import { useCallback, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

export interface RadioGroupProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<RadioGroupPropsBase, "id" | "onChange" | "ref"> {
  name: FieldPath<TFieldValues>;
  disabled?: boolean;
}

export function RadioGroup<TFieldValues extends FieldValues = FieldValues>({
  ...props
}: RadioGroupProps<TFieldValues>) {
  const {
    field: { ref: _, ...field },
    fieldState,
  } = useController<TFieldValues>({ name: props.name });

  const [value, setValue] = useState(props.options.find((option) => field.value?.value === option.value));

  const onChange = useCallback(
    (option: RadioGroupOption) => {
      field.onChange(option as any);
      setValue(option);
    },
    [field],
  );

  return (
    <RadioGroupBase
      error={fieldState.error?.message}
      {...props}
      id={props.name}
      {...field}
      value={value}
      onChange={onChange}
    />
  );
}
