import type { AuthenticationContextValue } from "components/providers/AuthenticationProvider";
import type { ReactNode } from "react";

import { NavigateWithParams } from "components/routing/NavigateWithParams";
import type { ProtectedRouteProps } from "./ProtectedRoute";
import { ProtectedRoute } from "./ProtectedRoute";

export interface AuthenticatedRouteProps extends Pick<ProtectedRouteProps, "fallback" | "loading"> {
  children: ReactNode;
}

export function AuthenticatedRoute({
  fallback = (
    <NavigateWithParams to="/sign-in" replace={true} state={{ error: "You must be signed in to view that page." }} />
  ),
  ...props
}: AuthenticatedRouteProps) {
  return (
    <ProtectedRoute
      {...props}
      condition={(auth: AuthenticationContextValue) => auth.authenticated}
      fallback={fallback}
    />
  );
}
