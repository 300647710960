import type { CardProps } from "@enzymefinance/ui";
import { Button, Card } from "@enzymefinance/ui";
import classNames from "classnames";
import type { ComponentPropsWithoutRef, HTMLAttributes, ReactElement, ReactNode } from "react";

type ActionCardProps<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">> = CardProps<TProps> &
  TProps & {
    description?: ReactNode;
    image: ReactElement<ComponentPropsWithoutRef<"svg">>;
    offsetImage?: boolean;
    title: string;
  };

export function ActionCard<TProps extends HTMLAttributes<HTMLElement> = ComponentPropsWithoutRef<"div">>({
  children,
  className,
  description,
  image,
  offsetImage = true,
  title,
  ...props
}: ActionCardProps<TProps>) {
  const wrapperClasses = classNames(
    "h-full flex flex-col hover:bg-base-400 group focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-base-100 transition",
    { "lg:mt-12 relative": offsetImage },
    className,
  );

  const imgClasses = classNames("flex-1 flex justify-center", {
    "lg:absolute lg:top-0 lg:inset-x-0 lg:-translate-y-1/2": offsetImage,
  });

  return (
    <Card className={wrapperClasses} {...props}>
      <Card.Content className="flex flex-1 flex-col justify-between space-y-6">
        <div className={imgClasses}>{image}</div>
        <div className="space-y-4">
          <div className="space-y-1">
            <h4 className="text-heading-content text-center font-bold">{title}</h4>
            {typeof description === "string" ? <p className="text-center text-sm">{description}</p> : description}
          </div>
          <div className="hidden lg:flex lg:flex-col">
            <Button as="span" className="group-hover:bg-primary-focus hover:bg-primary-focus text-center">
              {children}
            </Button>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
}
