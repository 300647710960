import { toAddress } from "@enzymefinance/environment";
import { ThreeDotsLoader } from "@enzymefinance/icons";
import { CogIcon } from "@enzymefinance/icons/solid";
import { LifeCycle, Utils } from "@enzymefinance/sdk";
import { Alert, Card, Image, SidebarNavigation } from "@enzymefinance/ui";
import baseLayer from "assets/base-layer.svg";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMyVaults } from "components/providers/MyVaultsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { Link } from "components/routing/Link";
import { useSettings } from "components/settings/Settings";
import { TransactionModal, useTransactionModal } from "components/transactions/TransactionModal";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useVaultDetailsQuery } from "queries/core";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { supportEmailAddress } from "utils/constants";
import { useExecutableMigration } from "utils/hooks/useExecutableMigration";
import { useSignaledMigration } from "utils/hooks/useSignaledMigration";
import { useTimelockRemainingForMigrationRequest } from "utils/hooks/useTimelockRemainingForMigrationRequest";
import { isAddressEqual } from "viem";
import { ActionCard } from "./ActionCard";
import { MigrationNotSignaled } from "./MigrationNotSignaled";
import { MigrationSignaled } from "./MigrationSignaled";
import type { Vault } from "./MyVaultsMenu";
import { MyVaultsMenu } from "./MyVaultsMenu";
import { Sidebar } from "./Sidebar";
import { VaultIsCurrent } from "./VaultIsCurrent";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export function MyVaults() {
  const [signerAddress] = useSigner();
  const { currentReleaseId, contracts } = useGlobals();
  const { client } = useNetwork();
  const { id } = useParams<"id">();

  const { loading: myVaultsLoading, vaults } = useMyVaults();
  const { selectedVaultId, setSelectedVaultId } = useSettings();
  const location = useLocation();

  const selectedVault = vaults.find((vault) => vault.id === selectedVaultId);

  const handleSetSelectedVault = useCallback(
    (vault: Vault) => {
      setSelectedVaultId(vault.id);
    },
    [setSelectedVaultId],
  );

  useEffect(() => {
    if (myVaultsLoading) {
      return;
    }

    // Switch select vault if user is directly associated
    if (id !== selectedVault?.id && (selectedVault?.manager || selectedVault?.depositor || selectedVault?.trader)) {
      const findVault = vaults.find((value) => value.id === id);

      if (findVault) {
        setSelectedVaultId(findVault.id);

        return;
      }
    }

    if (!selectedVault && vaults[0]) {
      // Prevents a deposit only vault being selected by default
      const findFirstManagedVault = vaults.find(({ manager, trader }) => manager || trader);

      if (findFirstManagedVault) {
        setSelectedVaultId(findFirstManagedVault.id);
      } else {
        handleSetSelectedVault(vaults[0]);
      }
    }
  }, [
    handleSetSelectedVault,
    id,
    myVaultsLoading,
    selectedVaultId,
    setSelectedVaultId,
    vaults,
    signerAddress,
    selectedVault,
  ]);

  const vaultQuery = useVaultDetailsQuery({
    skip: !selectedVault?.id,
    variables: selectedVault?.id ? { id: selectedVault.id.toLowerCase() } : undefined,
  });

  const vaultRelease = vaultQuery.data?.vault?.release.id;
  const upgradeable =
    typeof vaultRelease === "string" && !isAddressEqual(toAddress(currentReleaseId), toAddress(vaultRelease));

  const isOwner = useMemo(() => {
    const ownerAddress = vaultQuery.data?.vault?.owner.id;

    return Utils.Address.safeSameAddress(signerAddress, ownerAddress);
  }, [vaultQuery, signerAddress]);

  const {
    data: migrationSignaled,
    isLoading: signaledMigrationQueryLoading,
    isFetching: signaledMigrationQueryFetching,
  } = useSignaledMigration(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: selectedVault ? toAddress(selectedVault.id) : undefined,
  });

  const {
    data: executableMigrationData,
    isLoading: executableMigrationQueryLoading,
    isFetching: executableMigrationQueryFetching,
  } = useExecutableMigration(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: selectedVault ? toAddress(selectedVault.id) : undefined,
  });

  const upgradeExecutable = executableMigrationData === true;

  const {
    data: migrationTimelockRemaining,
    isLoading: timeLockRemainingLoading,
    isFetching: timeLockRemainingFetching,
  } = useTimelockRemainingForMigrationRequest(client, {
    dispatcher: contracts.Dispatcher,
    vaultProxy: selectedVault ? toAddress(selectedVault.id) : undefined,
  });
  const timelockRemaining = Number(migrationTimelockRemaining);

  const transaction = useTransactionModal();
  const start = transaction.start;

  const executeMigration = useCallback(
    (bypassFailure: boolean) => {
      if (!(signerAddress && selectedVault?.id)) {
        return;
      }

      const fn = LifeCycle.executeMigration({
        fundDeployer: contracts.FundDeployer,
        vaultProxy: toAddress(selectedVault.id),
        bypassPrevReleaseFailure: bypassFailure,
      });
      start(fn, signerAddress);
    },
    [contracts.FundDeployer, client, selectedVault?.id, signerAddress, start],
  );

  const cancelMigration = useCallback(
    (bypassFailure: boolean) => {
      if (!(signerAddress && selectedVault?.id)) {
        return;
      }

      const fn = LifeCycle.cancelMigration({
        fundDeployer: contracts.FundDeployer,
        vaultProxy: toAddress(selectedVault.id),
        bypassPrevReleaseFailure: bypassFailure,
      });

      start(fn, signerAddress);
    },
    [signerAddress, contracts.Dispatcher, contracts.FundDeployer, selectedVault?.id, start],
  );

  const durationRemaining = useMemo(
    () =>
      timelockRemaining !== undefined && timelockRemaining > 0
        ? dayjs.duration(timelockRemaining, "seconds").humanize()
        : undefined,
    [timelockRemaining],
  );

  const loading =
    myVaultsLoading ||
    (signaledMigrationQueryLoading && signaledMigrationQueryFetching) ||
    (executableMigrationQueryLoading && executableMigrationQueryFetching) ||
    (timeLockRemainingLoading && timeLockRemainingFetching);

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <ThreeDotsLoader className="w-8" />
      </div>
    );
  }

  if (!vaults.length) {
    return location.pathname === "/vault/create" ? null : (
      <ActionCard
        as={Link}
        className="block"
        description="Create an Enzyme vault and start managing your assets."
        image={<Image alt="" height="auto" src={baseLayer} width={96} />}
        appearance="secondary"
        title="Create your first vault"
        to="/vault/create"
      >
        Create a Vault
      </ActionCard>
    );
  }

  return (
    <>
      <TransactionModal {...transaction} />
      <Card appearance="secondary">
        <Card.Content>
          <MyVaultsMenu selected={selectedVault} setSelected={handleSetSelectedVault} vaults={vaults} />
          {selectedVault ? (
            vaultQuery.loading ? (
              <ThreeDotsLoader />
            ) : (
              <div className="px-2 pt-4">
                <SidebarNavigation className="-mx-4">
                  <Sidebar.Items>
                    {upgradeable ? (
                      selectedVault.manager ? (
                        migrationSignaled ? (
                          <>
                            <MigrationSignaled
                              cancelMigration={cancelMigration}
                              upgradeExecutable={upgradeExecutable}
                              executeMigration={executeMigration}
                              isOwner={isOwner}
                              durationRemaining={durationRemaining}
                            />
                            <Sidebar.Item icon={CogIcon} to={`/vault/${id}/settings`}>
                              Settings
                            </Sidebar.Item>
                          </>
                        ) : (
                          <>
                            <MigrationNotSignaled selectedVault={selectedVault} />
                            <Sidebar.Item icon={CogIcon} to={`/vault/${id}/settings`}>
                              Settings
                            </Sidebar.Item>
                          </>
                        )
                      ) : (
                        <div className="px-2">
                          <Alert appearance="warning" title="Upgrade available">
                            This vault has not been upgraded to the latest version. If you are a depositor in this vault
                            and you want to redeem your shares, please contact us at If you did not receive the code,
                            please contact our support at{" "}
                            <Link to={`mailto:${supportEmailAddress}`} className="text-link">
                              {supportEmailAddress}
                            </Link>
                          </Alert>
                        </div>
                      )
                    ) : (
                      <VaultIsCurrent
                        vault={vaultQuery.data?.vault}
                        signerAddress={signerAddress}
                        selectedVault={selectedVault}
                      />
                    )}
                  </Sidebar.Items>
                </SidebarNavigation>
              </div>
            )
          ) : null}
        </Card.Content>
      </Card>
    </>
  );
}
