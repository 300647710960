import { LoadingBoundary } from "components/common/LoadingBoundary";
import { Outlet } from "react-router";

export function VaultSettingsLayout() {
  return (
    <LoadingBoundary>
      <Outlet />
    </LoadingBoundary>
  );
}
