import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ProtocolBanner } from "components/vault/protocols/ProtocolBanner";
import { VaultAaveV2Navigation } from "components/vault/protocols/aave-v2/VaultAaveV2Navigation";
import { Outlet } from "react-router";

export function VaultAaveV2Layout() {
  return (
    <>
      <ProtocolBanner integrationId="aave-v2" />

      <VaultAaveV2Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
