import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { ArrowUpTrayIcon } from "@enzymefinance/icons/solid";
import { SidebarNavigation, Tooltip, TooltipProvider, useBoolean } from "@enzymefinance/ui";
import { useNetwork } from "components/providers/NetworkProvider";
import { VaultRedeemSharesModal } from "components/vault/redemption/VaultRedeemSharesModal";
import type { VaultDetailsFragment } from "queries/core";
import { useMemo } from "react";
import { useSharesWrapperRedemptionInfo } from "utils/hooks/useSharesWrapperRedemptionInfo";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { zeroAddress } from "viem";

import { useSingleAssetRedemptionQueueInfo } from "../../utils/hooks/useSingleAssetRedemptionQueueInfo";
import type { Vault } from "./MyVaultsMenu";

interface WithdrawSidebarItemProps {
  selectedVault?: Vault;
  signerAddress?: Address;
  vault: VaultDetailsFragment;
}

export function WithdrawSidebarItem({ signerAddress, vault, selectedVault }: WithdrawSidebarItemProps) {
  const { client } = useNetwork();
  const tokenBalanceQuery = useTokenBalance(client, { account: signerAddress, token: toAddress(vault.id) });

  const sharesWrapperQuery = useSharesWrapperRedemptionInfo({
    vaultId: toAddress(vault.id),
    comptroller: toAddress(vault.comptroller.id),
    account: signerAddress ?? zeroAddress,
  });

  const sharesWrapperInfo = useMemo(() => sharesWrapperQuery?.data ?? null, [sharesWrapperQuery]);
  const sharesWrapperInfoLoading = Boolean(sharesWrapperQuery?.loading);

  const redemptionQueueQuery = useSingleAssetRedemptionQueueInfo({
    vaultId: toAddress(vault.id),
    comptroller: toAddress(vault.comptroller.id),
    account: signerAddress ?? zeroAddress,
  });

  const redemptionQueueInfo = useMemo(() => redemptionQueueQuery?.data ?? null, [redemptionQueueQuery]);
  const redemptionQueueInfoLoading = Boolean(redemptionQueueQuery?.loading);

  const isDeposited = (tokenBalanceQuery.data ?? 0n) > 0n || (!signerAddress && selectedVault?.depositor);
  const [isOpen, open, close] = useBoolean();

  return isDeposited ? (
    <>
      <TooltipProvider>
        <Tooltip.Item className="flex flex-col">
          <SidebarNavigation.Item
            as="button"
            className="text-left"
            disabled={!signerAddress}
            onClick={open}
            icon={ArrowUpTrayIcon}
          >
            Redeem
          </SidebarNavigation.Item>
        </Tooltip.Item>
        {signerAddress ? null : (
          <Tooltip.Panel>Connect to the corresponding wallet to be able to redeem.</Tooltip.Panel>
        )}
      </TooltipProvider>
      {signerAddress ? (
        <VaultRedeemSharesModal
          close={close}
          vault={vault}
          isOpen={isOpen}
          signerAddress={signerAddress}
          comptrollerProxy={vault.comptroller.id}
          sharesWrapperRedemptionInfo={sharesWrapperInfo}
          redemptionQueueRedemptionInfo={redemptionQueueInfo}
          loading={sharesWrapperInfoLoading || redemptionQueueInfoLoading}
        />
      ) : null}
    </>
  ) : null;
}
