import { LoadingProvider } from "components/common/LoadingBoundary";
import { Toasts } from "components/common/Toasts";
import { AuthenticationProvider } from "components/providers/AuthenticationProvider";
import { CommonProviders } from "components/providers/CommonProviders";
import { MyVaultsProvider } from "components/providers/MyVaultsProvider";
import { TitleProvider } from "components/providers/TitleProvider";
import { BrowserRouter } from "components/routing/BrowserRouter";
import { createBrowserHistory } from "history";
import { initializeDebug } from "utils/debug";
import { initializeSentry } from "utils/sentry";

import { DarkLightModeProvider, useDarkLightMode } from "components/providers/DarkLightModeProvider";
import { AppRoutes } from "./AppRoutes";

const history = createBrowserHistory();

initializeSentry();
initializeDebug();

export function App() {
  return (
    <LoadingProvider>
      <BrowserRouter navigator={history}>
        <AuthenticationProvider>
          <DarkLightModeProvider>
            <Providers />
          </DarkLightModeProvider>
        </AuthenticationProvider>
      </BrowserRouter>
    </LoadingProvider>
  );
}

function Providers() {
  const { isDarkModeOn } = useDarkLightMode();

  return (
    <CommonProviders isWagmiDarkModeOn={isDarkModeOn}>
      <TitleProvider>
        <MyVaultsProvider>
          <AppRoutes />
          <Toasts />
        </MyVaultsProvider>
      </TitleProvider>
    </CommonProviders>
  );
}
