import type { Address } from "@enzymefinance/environment";
import { AssetType, toAddress } from "@enzymefinance/environment";
import {
  AddressCreatable,
  BigIntInput,
  Form,
  FormErrorMessage,
  SubmitButton,
  TokenPicker,
  useForm,
} from "@enzymefinance/hook-form";
import { SingleAssetRedemptionQueue } from "@enzymefinance/sdk/Tools";
import type { Viem } from "@enzymefinance/sdk/Utils";
import { Divider, Modal } from "@enzymefinance/ui";
import { address, asset, bigIntInput } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMyVaults } from "components/providers/MyVaultsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { getDefaultRedemptionAsset } from "components/tools/redemptionQueue/enable/utils";
import { TransactionModal, useTransactionModal } from "components/transactions/TransactionModal";
import { VaultLabel } from "components/vault/VaultLabel";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { isAddressEqual } from "viem";
import { z } from "zod";

const schema = z.object({
  redemptionAsset: asset(),
  managers: z.array(address({ caseInsensitive: true })),
  bypassableSharesThreshold: bigIntInput({
    decimals: 18,
    allowZero: true,
  }),
});

interface RedemptionQueueEnableModalProps {
  close: () => void;
  isOpen: boolean;
}

export function RedemptionQueueEnableModal({ close, isOpen }: RedemptionQueueEnableModalProps) {
  const { id: vaultId } = useParams<"id">();

  const [signerAddress] = useSigner();
  const { ownerships } = useMyVaults();
  const { deployment } = useNetwork();
  const { environment } = useGlobals();
  const transactions = useTransactionModal();

  const pickedVault = useMemo(() => {
    return vaultId ? ownerships.find(({ id }) => isAddressEqual(toAddress(id), toAddress(vaultId))) : undefined;
  }, [ownerships, vaultId]);

  const { getAssets } = useGlobals();
  const primitives = useMemo(() => getAssets({ registered: true, types: [AssetType.PRIMITIVE] }), [getAssets]);

  const startTransaction = useCallback(
    (sendFunction: Viem.PopulatedTransaction<any, any>, currentSignerAddress: Address, toVault: Address) => {
      transactions.start(sendFunction, currentSignerAddress, toVault);
      close();
    },
    [transactions, close],
  );

  const redemptionAsset = getDefaultRedemptionAsset(pickedVault?.comptroller.denomination.id, primitives);

  const form = useForm({
    defaultValues: {
      redemptionAsset,
      managers: [],
      bypassableSharesThreshold: 0n,
    },
    onSubmit: (values, helpers) => {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        if (vaultId === undefined) {
          return helpers.setError("form", {
            message: "No vault selected",
          });
        }

        const fn = SingleAssetRedemptionQueue.deployProxy({
          redemptionQueueFactory: environment.contracts.SingleAssetRedemptionQueueFactory,
          vaultProxy: toAddress(vaultId),
          managers: values.managers,
          redemptionAsset: values.redemptionAsset.id,
          bypassableSharesThreshold: values.bypassableSharesThreshold ?? 0n,
        });

        startTransaction(fn, signerAddress, toAddress(vaultId));
        close();
      } catch (error) {
        return helpers.setError("form", {
          message: `Error submitting the transaction: ${error.message}`,
        });
      }
    },
    schema,
  });

  useEffect(() => {
    if (redemptionAsset) {
      form.setValue("redemptionAsset", redemptionAsset);
    }
  }, [redemptionAsset]);

  return (
    <>
      <TransactionModal {...transactions} />
      <Modal isOpen={isOpen} dismiss={close} title="Enable redemption queue">
        <Form form={form}>
          <Modal.Body className="flex-column space-y-4">
            <div className="flex items-center justify-between space-x-3">
              <p className="text-sm">Vault</p>
              <VaultLabel reverse={true} id={vaultId} deployment={deployment} name={pickedVault?.name} />
            </div>
            <Divider appearance="subtle" />
            <div className="flex space-x-3">
              <TokenPicker name="redemptionAsset" label="Redemption asset" kind="modal" options={primitives}>
                <TokenPicker.Button
                  name="redemptionAsset"
                  className="bg-base-300 group flex w-full !px-4 [&>span]:!justify-between"
                  appearance="quaternary"
                >
                  <span className="text-heading-content text-sm">Select a token</span>
                </TokenPicker.Button>
              </TokenPicker>
            </div>
            <Divider appearance="subtle" />
            <BigIntInput name="bypassableSharesThreshold" label="Bypassable Shares Threshold" />

            <Divider appearance="subtle" />
            <div className="space-y-3">
              <p className="text-sm">Redemption Queue manager wallet addresses</p>
              <AddressCreatable isMulti={true} name="managers" label="Set addresses" labelHidden={true} />
            </div>
          </Modal.Body>
          <Modal.Actions>
            <SubmitButton disabled={!vaultId}>Enable</SubmitButton>
          </Modal.Actions>
          <FormErrorMessage />
        </Form>
      </Modal>
    </>
  );
}
