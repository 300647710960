import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ProtocolBanner } from "components/vault/protocols/ProtocolBanner";
import { VaultCompoundV2Navigation } from "components/vault/protocols/compound-v2/VaultCompoundV2Navigation";
import { Outlet } from "react-router";

export function VaultCompoundV2Layout() {
  return (
    <>
      <ProtocolBanner integrationId="compound-v2" />
      <VaultCompoundV2Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
