import type { Address } from "@enzymefinance/environment";
import { AssetType, toAddress } from "@enzymefinance/environment";
import {
  AddressCreatable,
  Form,
  Input,
  NumberInput,
  RadioGroup,
  Select,
  SubmitButton,
  TokenPicker,
  useForm,
} from "@enzymefinance/hook-form";
import { GatedRedemptionQueueSharesWrapper } from "@enzymefinance/sdk/Tools";
import type { Viem } from "@enzymefinance/sdk/Utils";
import { Divider, Modal } from "@enzymefinance/ui";
import { address, asset, numberInput, percentage } from "@enzymefinance/validation";
import { useSigner } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useMyVaults } from "components/providers/MyVaultsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { SharesWrapperCheckbox } from "components/tools/sharesWrapper/enable/components/SharesWrapperCheckbox";
import type { WindowDurationOption } from "components/tools/sharesWrapper/enable/utils";
import {
  calculateDuration,
  depositModeOptions,
  getDefaultRedemptionAsset,
  getTomorrowDefaultDate,
  windowDurationOptions,
} from "components/tools/sharesWrapper/enable/utils";
import { TransactionModal, useTransactionModal } from "components/transactions/TransactionModal";
import { VaultLabel } from "components/vault/VaultLabel";
import dayjs from "dayjs";
import { GatedRedemptionQueueSharesWrapperDepositMode } from "queries/core";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { extractCallError } from "utils/error";
import { isAddressEqual, parseUnits } from "viem";
import { z } from "zod";

function createSchema() {
  return z.object({
    redemptionAsset: asset(),
    relativeSharesCap: numberInput(percentage(z.number().max(100, "The relative shares cap must be 100% or smaller."))),
    useDepositApproval: z.boolean(),
    useRedemptionApproval: z.boolean(),
    useTransferApproval: z.boolean(),
    depositMode: z.object({
      label: z.string(),
      value: z.nativeEnum(GatedRedemptionQueueSharesWrapperDepositMode),
    }),
    managers: z.array(address({ caseInsensitive: true })),
    dateOfFirstWindowStart: z.string().refine(
      (value) => {
        const firstWindowStartDate = dayjs().add(15, "minutes");

        return dayjs(value).isAfter(firstWindowStartDate);
      },
      {
        message: "The date must be greater than 15 minutes from the current time.",
      },
    ),
    requestWindowDuration: z.string().refine((value) => Number(value) > 0, {
      message: "Greater than 1",
    }),
    requestWindowDurationSelect: z.object({
      value: z.string(),
      label: z.string(),
    }),
    redemptionExecutionWindowDuration: z.string().refine((value) => Number(value) > 0, {
      message: "Greater than 1",
    }),
    redemptionExecutionWindowDurationSelect: z.object({
      value: z.string(),
      label: z.string(),
    }),
  });
}

interface SharesWrapperEnableModalProps {
  close: () => void;
  isOpen: boolean;
}

export function SharesWrapperEnableModal({ close, isOpen }: SharesWrapperEnableModalProps) {
  const { id: vaultId } = useParams<"id">();

  const [signerAddress] = useSigner();
  const { ownerships } = useMyVaults();
  const { deployment } = useNetwork();
  const { environment } = useGlobals();
  const transactions = useTransactionModal();

  const pickedVault = useMemo(() => {
    return vaultId ? ownerships.find(({ id }) => isAddressEqual(toAddress(id), toAddress(vaultId))) : undefined;
  }, [ownerships, vaultId]);

  const { getAssets } = useGlobals();
  const primitives = useMemo(() => getAssets({ registered: true, types: [AssetType.PRIMITIVE] }), [getAssets]);

  const schema = useMemo(() => createSchema(), []);

  const startTransaction = useCallback(
    (sendFunction: Viem.PopulatedTransaction<any, any>, currentSignerAddress: Address, toVault: Address) => {
      transactions.start(sendFunction, currentSignerAddress, toVault);
      close();
    },
    [transactions, close],
  );

  const redemptionAsset = getDefaultRedemptionAsset(pickedVault?.comptroller.denomination.id, primitives);

  const form = useForm({
    defaultValues: {
      redemptionAsset,
      relativeSharesCap: "100",
      useDepositApproval: true,
      useRedemptionApproval: true,
      useTransferApproval: true,
      depositMode: depositModeOptions[0],
      managers: [],
      dateOfFirstWindowStart: getTomorrowDefaultDate(),
      requestWindowDuration: "7",
      requestWindowDurationSelect: windowDurationOptions[3],
      redemptionExecutionWindowDuration: "7",
      redemptionExecutionWindowDurationSelect: windowDurationOptions[3],
    },
    onSubmit: (values, helpers) => {
      try {
        if (!signerAddress) {
          return helpers.setError("form", {
            message: "Wallet not connected. You must connect your wallet to perform this action.",
          });
        }

        if (vaultId === undefined) {
          return helpers.setError("form", {
            message: "No vault selected",
          });
        }

        const requestWindowDuration = calculateDuration(
          Number(values.requestWindowDuration),
          values.requestWindowDurationSelect.value as WindowDurationOption,
        );

        const redemptionExecutionWindowDuration = calculateDuration(
          Number(values.requestWindowDuration),
          values.redemptionExecutionWindowDurationSelect.value as WindowDurationOption,
        );

        const windowConfig = {
          firstWindowStart: BigInt(dayjs(values.dateOfFirstWindowStart).unix()),
          duration: redemptionExecutionWindowDuration,
          frequency: redemptionExecutionWindowDuration + requestWindowDuration,
          relativeSharesCap: parseUnits(values.relativeSharesCap.toString(), 18),
        };

        const depositMode =
          values.depositMode.value === GatedRedemptionQueueSharesWrapperDepositMode.DIRECT
            ? GatedRedemptionQueueSharesWrapper.DepositMode.Direct
            : GatedRedemptionQueueSharesWrapper.DepositMode.Request;

        const fn = GatedRedemptionQueueSharesWrapper.deploy({
          sharesWrapperFactory: environment.contracts.GatedRedemptionQueueSharesWrapperFactory,
          vaultProxy: toAddress(vaultId),
          managers: values.managers,
          redemptionAsset: values.redemptionAsset.id,
          useDepositApproval: values.useDepositApproval,
          useRedemptionApproval: values.useRedemptionApproval,
          useTransferApproval: values.useTransferApproval,
          depositMode,
          windowConfig,
        });

        startTransaction(fn, signerAddress, toAddress(vaultId));
        close();
      } catch (error) {
        const humanizedError = extractCallError(error);
        throw new Error(`${humanizedError.title}: ${humanizedError.message}`);
      }
    },
    schema,
  });

  useEffect(() => {
    if (redemptionAsset) {
      form.setValue("redemptionAsset", redemptionAsset);
    }
  }, [redemptionAsset]);

  return (
    <>
      <TransactionModal {...transactions} />
      <Modal isOpen={isOpen} dismiss={close} title="Enable shares wrapper">
        <Form form={form}>
          <Modal.Body className="flex-column space-y-4">
            <div className="flex items-center justify-between space-x-3">
              <p className="text-sm">Vault</p>
              <VaultLabel reverse={true} id={vaultId} deployment={deployment} name={pickedVault?.name} />
            </div>
            <Divider appearance="default" />
            <div className="flex space-x-3">
              <TokenPicker name="redemptionAsset" label="Redemption asset" kind="modal" options={primitives}>
                <TokenPicker.Button
                  name="redemptionAsset"
                  className="bg-base-300 group flex w-full !px-4 [&>span]:!justify-between"
                  appearance="quaternary"
                >
                  <span className="text-heading-content text-sm">Select a token</span>
                </TokenPicker.Button>
              </TokenPicker>
              <NumberInput
                className="mt-0 p-3"
                label="Relative shares cap"
                name="relativeSharesCap"
                numberFormat={{ style: "percent" }}
                max={100}
              />
            </div>
            <Divider appearance="subtle" />
            <div className="space-y-3">
              <p className="text-sm">Approvals</p>
              <div className="md:flex">
                <SharesWrapperCheckbox name="useDepositApproval" label="Deposit" />
                <SharesWrapperCheckbox name="useRedemptionApproval" label="Redemption" />
                <SharesWrapperCheckbox name="useTransferApproval" label="Transfer" />
              </div>
            </div>
            <Divider appearance="subtle" />
            <div className="space-y-3">
              <p className="text-sm">Deposit mode</p>
              <RadioGroup
                name="depositMode"
                optionContainerClassName="flex"
                label={null}
                options={depositModeOptions}
                optionRenderer={(option) => (
                  <div className="space-y-2 sm:space-y-0">
                    <p className="body-contaste text-sm font-medium">{option.label}</p>
                  </div>
                )}
              />
            </div>
            <Divider appearance="subtle" />
            <div className="space-y-3">
              <div className="space-y-3 pr-1 sm:w-full md:w-[50%]">
                <p className="text-base-content text-sm">Redemption windows</p>
                <Input
                  readOnly={false}
                  name="dateOfFirstWindowStart"
                  type="datetime-local"
                  label="Date of first window start"
                />
              </div>
              <div className="md:flex md:space-x-2">
                <div className="mt-3 space-y-1 md:mt-1">
                  <div className="text-sm">Request window duration</div>
                  <div className="flex space-x-2">
                    <NumberInput label={null} name="requestWindowDuration" />
                    <Select
                      isClearable={false}
                      name="requestWindowDurationSelect"
                      label={null}
                      options={windowDurationOptions}
                    />
                  </div>
                </div>
                <div className="mt-3 space-y-1 md:mt-1">
                  <div className="text-sm">Redemption window duration</div>
                  <div className="flex space-x-2">
                    <NumberInput label={null} name="redemptionExecutionWindowDuration" />
                    <Select
                      isClearable={false}
                      name="redemptionExecutionWindowDurationSelect"
                      label={null}
                      options={windowDurationOptions}
                    />
                  </div>
                </div>
              </div>
              <Divider appearance="subtle" />
              <div className="space-y-3">
                <p className="text-sm">Manager wallet address</p>
                <AddressCreatable isMulti={true} name="managers" label="Set addresses" labelHidden={true} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Actions>
            <SubmitButton disabled={!vaultId}>Enable</SubmitButton>
          </Modal.Actions>
        </Form>
      </Modal>
    </>
  );
}
