import { Alert, Button } from "@enzymefinance/ui";
import { Link } from "components/routing/Link";

import type { Vault } from "./MyVaultsMenu";

interface MigrationNotSignaledProps {
  selectedVault?: Vault;
}

export function MigrationNotSignaled({ selectedVault }: MigrationNotSignaledProps) {
  return (
    <div className="px-2">
      <Alert appearance="warning" title="Upgrade available">
        <div className="space-y-4">
          <p>We recommend that you upgrade your vault to the latest version.</p>
          <Button as={Link} to={`/vault/${selectedVault?.id}/settings/upgrade`}>
            Upgrade Now
          </Button>
        </div>
      </Alert>
    </div>
  );
}
