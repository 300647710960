import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation, PageHeading } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

export function DashboardNavigation() {
  return (
    <>
      <PageHeading>Dashboard</PageHeading>
      <nav aria-label="Dashboard">
        <Navigation direction="horizontal">
          <DashboardNavigationItem to="/dashboard/deposits">My Deposits</DashboardNavigationItem>
          <DashboardNavigationItem to="/dashboard/vaults">My Vaults</DashboardNavigationItem>
          <DashboardNavigationItem to="/dashboard/activity">Activity</DashboardNavigationItem>
        </Navigation>
      </nav>
    </>
  );
}

type DashboardNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function DashboardNavigationItem(props: DashboardNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}
