import { Button, Checkbox, ExpandableButton, Tooltip } from "@enzymefinance/ui";
import { useToggle } from "react-use";

interface MigrationSignaledProps {
  cancelMigration: (bypassFailure: boolean) => void;
  upgradeExecutable: boolean;
  executeMigration: (bypassFailure: boolean) => void;
  durationRemaining?: string;
  isOwner: boolean;
}

export function MigrationSignaled({
  cancelMigration,
  upgradeExecutable,
  executeMigration,
  durationRemaining,
  isOwner,
}: MigrationSignaledProps) {
  const [advanced, toggleAdvanced] = useToggle(false);
  const [bypassFailure, toggleBypassFailure] = useToggle(false);

  return (
    <div className="space-y-4">
      <p>An upgrade has been signaled for this vault</p>
      <div className="flex flex-col space-y-4">
        <Button disabled={!isOwner} appearance="destructive" onClick={() => cancelMigration(bypassFailure)}>
          Cancel Upgrade
        </Button>
        <Button disabled={!(isOwner && upgradeExecutable)} onClick={() => executeMigration(bypassFailure)}>
          {upgradeExecutable ? "Execute Upgrade" : `Execution available in ${durationRemaining}`}
        </Button>
      </div>
      <ExpandableButton isOpen={advanced} handleExpand={toggleAdvanced} label="Advanced Settings">
        <Checkbox
          id="bypassFailure"
          name="bypassFailure"
          label={
            <Tooltip label="Bypass validation">
              In some circumstances, executing or cancelling a migration may fail. If this occurs, you may wish to
              bypass that failure to perform the action anyway. Before doing so, make sure you are aware of the reason
              for the failure. In doubt, reach out to the team via email at migration@enzyme.finance.
            </Tooltip>
          }
          onChange={toggleBypassFailure}
          checked={bypassFailure}
        />
      </ExpandableButton>
    </div>
  );
}
