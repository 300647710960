import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";

export function NetworkNavigation() {
  return (
    <nav aria-label="Network">
      <Navigation direction="horizontal" className="mb-4 lg:mb-0 flex justify-between sm:justify-start">
        <NetworkNavigationItem to="/network/information" className="flex-1 text-center sm:flex-initial">
          Information
        </NetworkNavigationItem>
        <NetworkNavigationItem to="/network/activity" className="flex-1 text-center sm:flex-initial">
          Activity
        </NetworkNavigationItem>
      </Navigation>
    </nav>
  );
}

type NetworkNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function NetworkNavigationItem(props: NetworkNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}
