import { Compass, CompassOutline } from "@enzymefinance/icons";
import {
  BoltIcon as OutlineBoltIcon,
  CircleStackIcon as OutlineCircleStackIcon,
  KeyIcon as OutlineKeyIcon,
} from "@enzymefinance/icons/outline";
import { Bars3BottomLeftIcon, BoltIcon, CircleStackIcon, HomeIcon, KeyIcon } from "@enzymefinance/icons/solid";
import type { SidebarNavigationItemProps } from "@enzymefinance/ui";
import { Image, SidebarNavigation } from "@enzymefinance/ui";
import etherLayer from "assets/ether-layer.svg";
import classNames from "classnames";
import { useConnectionModal, useSigner } from "components/connection/Connection.js";
import { useAuthentication } from "components/providers/AuthenticationProvider";
import { useMyVaults } from "components/providers/MyVaultsProvider";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useLocation } from "react-router-dom";
import { useHeaderBanner } from "utils/hooks/useHeaderBanner";

import { AccountNotificationsMenu } from "./AccountNotificationsMenu";
import { ActionCard } from "./ActionCard";
import { MyVaults } from "./MyVaults";

export function Sidebar() {
  const [signerAddress] = useSigner();
  const { authenticated } = useAuthentication();
  const { open } = useConnectionModal();
  const { vaults } = useMyVaults();
  const headerBanner = useHeaderBanner();
  const connected = !!signerAddress;
  const location = useLocation();

  const wrapperClasses = classNames("sticky", {
    "top-[116px]": headerBanner !== null,
    "top-[70px]": headerBanner === null,
  });
  const classes = classNames(
    "bg-base-200 backdrop-filter backdrop-blur rounded-2xl flex flex-col space-y-10 px-4 py-8",
    {
      "justify-between": !vaults.length,
      "min-h-[calc(100vh-102px)]": headerBanner === null,
      "min-h-[calc(100vh-148px)]": headerBanner !== null,
    },
  );

  return (
    <>
      {/* Top space equivalent to the Navbar height */}
      <div className={wrapperClasses}>
        <div className="scrollbar-hide h-[calc(100vh-70px)] overflow-y-auto py-4">
          <div className={classes}>
            <SidebarNavigation>
              <Sidebar.Items>
                <Sidebar.Item icon={location.pathname === "/discover" ? Compass : CompassOutline} to="/discover">
                  Discover
                </Sidebar.Item>
                <Sidebar.Item
                  icon={location.pathname === "/discover/vaults" ? KeyIcon : OutlineKeyIcon}
                  to="/discover/vaults"
                >
                  Vaults
                </Sidebar.Item>
                <Sidebar.Item icon={Bars3BottomLeftIcon} to="/network/information">
                  Network
                </Sidebar.Item>
                <Sidebar.Item
                  icon={location.pathname === "/discover/assets" ? CircleStackIcon : OutlineCircleStackIcon}
                  to="/discover/assets"
                >
                  Assets
                </Sidebar.Item>
                <Sidebar.Item
                  icon={location.pathname === "/discover/integrations" ? BoltIcon : OutlineBoltIcon}
                  to="/discover/integrations"
                >
                  Integrations
                </Sidebar.Item>
                <Sidebar.Item icon={HomeIcon} to="/dashboard">
                  Dashboard
                </Sidebar.Item>
              </Sidebar.Items>
            </SidebarNavigation>

            {connected || authenticated ? (
              <>
                <MyVaults />
                <AccountNotificationsMenu />
              </>
            ) : (
              <ActionCard
                description="Discover new possibilities and more features by connecting your wallet."
                image={<Image alt="" height="auto" src={etherLayer} width={96} />}
                onClick={open}
                appearance="secondary"
                role="button"
                tabIndex={0}
                title="Get started by connecting your wallet"
              >
                Connect Wallet
              </ActionCard>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

Sidebar.Items = SidebarNavigation.Items;

type SidebarItemProps = Omit<SidebarNavigationItemProps<LinkProps>, "as" | "isCurrent">;

function sidebarItem(props: SidebarItemProps) {
  const location = useLocation();
  // Checks for specific route exceptions between a Link's to path and the actual pathname
  const isCurrentToPath = (to: string) =>
    location.pathname.startsWith(props.to.toString()) && props.to.toString() === to;
  const isCurrent =
    location.pathname === props.to.toString() || isCurrentToPath("/dashboard") || isCurrentToPath("/network");

  return <SidebarNavigation.Item {...props} as={Link} isCurrent={isCurrent} isSidebar={true} />;
}

Sidebar.Item = sidebarItem;
