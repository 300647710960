import { LoadingBoundary } from "components/common/LoadingBoundary";
import { DeploymentMenu } from "components/menu/DeploymentMenu";
import { VaultProvider } from "components/vault/VaultProvider";
import { VaultBreadcrumbs } from "components/vault/heading/VaultBreadcrumbs";
import { VaultNavigation } from "components/vault/heading/VaultNavigation";
import { VaultPageHeading } from "components/vault/heading/VaultPageHeading";
import { Outlet } from "react-router";
import NotFoundRoute from "routes/NotFoundRoute";

import { PageLayout } from "./PageLayout";

export function VaultPublicLayout() {
  return (
    <VaultProvider
      // Need to be passed as prop so that suspense in the loading boundary fallbacks correctly
      fallback={
        <NotFoundRoute
          actions={<DeploymentMenu size="xl" />}
          description={
            <>
              <p>We could not find the vault you were looking for.</p>
              <p>Try to switch to a different network or proceed with one of the actions below.</p>
            </>
          }
          title="Oh no! Vault not found."
        />
      }
    >
      <PageLayout
        header={
          <>
            <VaultBreadcrumbs />
            <VaultPageHeading />
            <VaultNavigation />
          </>
        }
        className="space-y-4 lg:space-y-8"
      >
        <LoadingBoundary>
          <Outlet />
        </LoadingBoundary>
      </PageLayout>
    </VaultProvider>
  );
}
