import type { Address } from "@enzymefinance/environment";
import { toAddress } from "@enzymefinance/environment";
import { ChevronDownIcon } from "@enzymefinance/icons/solid";
import { Utils } from "@enzymefinance/sdk";
import type { ButtonProps } from "@enzymefinance/ui";
import { Alert, Button, IconLabel, Menu, useBoolean } from "@enzymefinance/ui";
import { useSigner } from "components/connection/Connection.js";
import { useNetwork } from "components/providers/NetworkProvider";
import { InlineLink } from "components/routing/Link";
import { SharesWrapperEnableModal } from "components/tools/sharesWrapper/enable/SharesWrapperEnableModal";
import { VaultLabel } from "components/vault/VaultLabel";
import type {
  SharesWrapperDetailsFragment,
  SharesWrapperManagerInterfaceVaultsQuery,
  VaultListItemFragment,
} from "queries/core";
import { useMySharesWrapperVaultsQuery, useSharesWrapperManagerInterfaceVaultsQuery } from "queries/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSharesWrapper } from "utils/sharesWrapper";
import { isAddressEqual } from "viem";

export interface SharesWrapperFilteredVaultsInterface {
  allowedDepositPolicy: boolean;
  sharesWrapperId: Address | null;
  vault: VaultListItemFragment;
}

interface SharesWrapperManagerType {
  vault: VaultListItemFragment;
  allowedDepositRecipientsPolicies: {
    enabled: boolean;
    addressLists: {
      items: string[];
    }[];
  }[];
  gatedRedemptionQueueSharesWrappers: SharesWrapperDetailsFragment[];
}

interface SharesWrapperVaultSelectionProps {
  onChangeSharesWrapper: (data: SharesWrapperFilteredVaultsInterface) => void;
}

export function SharesWrapperVaultSelection({ onChangeSharesWrapper }: SharesWrapperVaultSelectionProps) {
  const { id: vaultId } = useParams<"id">();
  const [isOpen, open, close] = useBoolean();
  const { deployment } = useNetwork();
  const navigate = useNavigate();
  const [signerAddress] = useSigner();
  const [currentSignerAddress, setCurrentSignerAddress] = useState<Address | undefined>(undefined);

  const [vaults, setVaults] = useState<SharesWrapperFilteredVaultsInterface[]>([]);

  const mySharesWrapperVaultsQuery = useMySharesWrapperVaultsQuery({
    variables: signerAddress
      ? {
          accountId: signerAddress,
        }
      : undefined,
  });

  const managedVaults = useMemo(
    () =>
      mySharesWrapperVaultsQuery.data?.account?.gatedRedemptionQueueSharesWrapperManagements.map(
        ({ vault }) => vault,
      ) ?? [],
    [mySharesWrapperVaultsQuery.data?.account?.gatedRedemptionQueueSharesWrapperManagements],
  );

  const ownerships = useMemo(
    () => mySharesWrapperVaultsQuery.data?.account?.ownerships ?? [],
    [mySharesWrapperVaultsQuery.data?.account?.ownerships],
  );

  const myVaults = useMemo(
    () => [...new Map([...managedVaults, ...ownerships].map((item) => [item.id, item])).values()],
    [managedVaults, ownerships],
  );

  useSharesWrapperManagerInterfaceVaultsQuery({
    variables: {
      vaultIds: myVaults.map((vault) => vault.id),
      comptrollerIds: myVaults.map((vault) => vault.comptroller.id),
    },
    skip: !myVaults.length, // Skip the query if ownerships are empty
    onCompleted: (data) => {
      const resp = processManagerInterfaceVaultsData(data);
      setVaults(resp);
      handleVaultSelection(resp);
    },
  });

  const selectVault = useCallback(
    (data: SharesWrapperFilteredVaultsInterface) => {
      onChangeSharesWrapper(data);

      if (data.sharesWrapperId) {
        navigate(`vault/${data.vault.id}/deposits`);
      } else {
        navigate(`vault/${data.vault.id}`);
      }
    },
    [navigate, onChangeSharesWrapper],
  );

  const handleVaultSelection = useCallback(
    (data: SharesWrapperFilteredVaultsInterface[]) => {
      if (data.length > 0 && vaultId) {
        const selectedVault = data.find(({ vault }) => isAddressEqual(toAddress(vault.id), toAddress(vaultId)));

        if (selectedVault) {
          selectVault(selectedVault);
        } else {
          navigate("/tools/shares-wrapper");
        }
      }
    },
    [navigate, selectVault, vaultId],
  );

  const filterVaultsWithSharesWrapper = useCallback((data: SharesWrapperManagerType) => {
    const { allowedDepositRecipientsPolicies, gatedRedemptionQueueSharesWrappers, vault } = data;

    if (!allowedDepositRecipientsPolicies[0]) {
      return {
        allowedDepositPolicy: false,
        sharesWrapperId: gatedRedemptionQueueSharesWrappers[0]?.id
          ? toAddress(gatedRedemptionQueueSharesWrappers[0].id)
          : null,
        vault,
      };
    }

    const sharesWrapper = getSharesWrapper({
      gatedRedemptionQueueSharesWrappers,
      allowedDepositRecipientsPolicy: allowedDepositRecipientsPolicies[0],
    });

    return {
      allowedDepositPolicy: !!sharesWrapper,
      sharesWrapperId: gatedRedemptionQueueSharesWrappers[0]?.id
        ? toAddress(gatedRedemptionQueueSharesWrappers[0].id)
        : null,
      vault,
    };
  }, []);

  const processManagerInterfaceVaultsData = useCallback(
    (data: SharesWrapperManagerInterfaceVaultsQuery | undefined) =>
      myVaults.map((vault) => {
        const allowedDepositRecipientsPolicies =
          data?.allowedDepositRecipientsPolicies.filter((policy) =>
            Utils.Address.safeSameAddress(policy.comptroller.vault?.id, vault.id),
          ) ?? [];

        const gatedRedemptionQueueSharesWrappers =
          data?.gatedRedemptionQueueSharesWrappers.filter((sharesWrapper) =>
            isAddressEqual(toAddress(sharesWrapper.vault.id), toAddress(vault.id)),
          ) ?? [];

        return filterVaultsWithSharesWrapper({
          allowedDepositRecipientsPolicies,
          gatedRedemptionQueueSharesWrappers,
          vault,
        });
      }),
    [filterVaultsWithSharesWrapper, myVaults],
  );

  useEffect(() => {
    if (Boolean(signerAddress) && !currentSignerAddress) {
      setCurrentSignerAddress(signerAddress);
    }

    if (signerAddress && currentSignerAddress && !isAddressEqual(signerAddress, currentSignerAddress)) {
      navigate("/");
    }
  }, [signerAddress, currentSignerAddress, navigate]);

  const selectedVault = useMemo(() => {
    return vaults.find(({ vault }) => Utils.Address.safeSameAddress(vault.id, vaultId));
  }, [vaultId, vaults]);

  const isSharesWrapperEnabled = useMemo(() => {
    return !!selectedVault?.sharesWrapperId;
  }, [selectedVault]);

  const isAllowedDepositPolicy = useMemo(() => {
    return !!selectedVault?.allowedDepositPolicy;
  }, [selectedVault?.allowedDepositPolicy]);

  if (vaults.length === 0) {
    return <p>No vaults</p>;
  }

  return (
    <>
      <div className="bg-base-200 p-4 sm:rounded-2xl md:flex md:flex-1 md:items-center md:justify-between">
        <Menu>
          <Menu.Button<ButtonProps>
            as={Button}
            className="bg-base-300 block w-full sm:inline-block sm:w-auto"
            appearance="tertiary"
            size="xl"
            trailingIcon={ChevronDownIcon}
          >
            {selectedVault ? (
              <VaultLabel
                id={selectedVault.vault.id}
                deployment={deployment}
                name={selectedVault.vault.name}
                className="w-full"
              />
            ) : (
              <IconLabel aria-hidden={true} size="xl" space={3} title="Select Vault" className="w-full" />
            )}
          </Menu.Button>
          <Menu.Items>
            <Menu.Group>
              {vaults.map(({ vault, sharesWrapperId, allowedDepositPolicy }) => (
                <Menu.Item
                  as="button"
                  key={vault.id}
                  onClick={() =>
                    selectVault({
                      vault,
                      sharesWrapperId,
                      allowedDepositPolicy,
                    })
                  }
                  className="hover:bg-base-100"
                >
                  <VaultLabel id={vault.id} deployment={deployment} name={vault.name} space={3} lines={2} />
                </Menu.Item>
              ))}
            </Menu.Group>
          </Menu.Items>
        </Menu>
        {isSharesWrapperEnabled ? null : (
          <Button onClick={open} disabled={!selectedVault} className="my-2 block w-full sm:inline-block sm:w-auto">
            Enable shares wrapper
          </Button>
        )}
        <SharesWrapperEnableModal isOpen={isOpen} close={close} />
      </div>
      {!isAllowedDepositPolicy && isSharesWrapperEnabled ? (
        <Alert appearance="info">
          {`To finish setting up the Shares Wrapper for the ${selectedVault?.vault.name}, add the Shares Wrapper contract
          address ${selectedVault?.sharesWrapperId} to the two policies "Limit Wallets Permitted To Deposit" and Restrict
          Wallets Permitted To Receive A Share Transfer. To change those policies, go`}{" "}
          <InlineLink to={`/vault/${selectedVault?.vault.id}/settings/policies`}>here</InlineLink>
        </Alert>
      ) : null}
    </>
  );
}
