import { Badge, PageHeading } from "@enzymefinance/ui";
import { LoadingBoundary } from "components/common/LoadingBoundary";
import { PageLayout } from "components/layout/PageLayout";
import { useNetwork } from "components/providers/NetworkProvider";
import { Outlet } from "react-router-dom";

import { NetworkNavigation } from "../network/NetworkNavigation";

export function NetworkLayout() {
  const { deployment } = useNetwork();

  return (
    <PageLayout
      header={
        <div className="wrap flex items-center justify-start space-x-2">
          <PageHeading>Network</PageHeading>
          <Badge className="capitalize">{deployment}</Badge>
        </div>
      }
    >
      <div className="-mb-8">
        <NetworkNavigation />
      </div>
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </PageLayout>
  );
}
