import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export function SharesWrapperToolNavigation() {
  const { id: vaultId } = useParams<"id">();
  const routeTo = useMemo(() => `/tools/shares-wrapper/vault/${vaultId}`, [vaultId]);

  return (
    <nav aria-label="SharesWrapperTool">
      <Navigation direction="horizontal" className="lg:border-base-200 lg:flex-nowrap lg:justify-between lg:border-b-2">
        <SharesWrapperToolNavigationItem to={`${routeTo}/deposits`}>Deposits</SharesWrapperToolNavigationItem>
        <SharesWrapperToolNavigationItem to={`${routeTo}/transfers`}>Transfers</SharesWrapperToolNavigationItem>
        <SharesWrapperToolNavigationItem to={`${routeTo}/redemptions`}>Redemptions</SharesWrapperToolNavigationItem>
        <SharesWrapperToolNavigationItem to={`${routeTo}/depositors`}>Depositors</SharesWrapperToolNavigationItem>
        <SharesWrapperToolNavigationItem to={`${routeTo}/managers`}>Managers</SharesWrapperToolNavigationItem>
        <SharesWrapperToolNavigationItem to={`${routeTo}/configuration`}>Configuration</SharesWrapperToolNavigationItem>
      </Navigation>
    </nav>
  );
}

type ConvexWrapperToolNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function SharesWrapperToolNavigationItem(props: ConvexWrapperToolNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} className="text-center lg:w-full" />;
}
