import { MoonIcon, SunIcon } from "@enzymefinance/icons/src/outline";
import { Select } from "@enzymefinance/select";
import { Icon } from "@enzymefinance/ui";
import { useDarkLightMode } from "components/providers/DarkLightModeProvider";

const options = [
  {
    value: "dark",
    label: (
      <div className="flex items-center space-x-2">
        <Icon icon={MoonIcon} /> <span>Dark</span>
      </div>
    ),
  },
  {
    value: "light",
    label: (
      <div className="flex items-center space-x-2">
        <Icon icon={SunIcon} /> <span>Light</span>
      </div>
    ),
  },
] as const;

export function DarkModeSelect() {
  const { isDarkModeOn, switchDarkMode } = useDarkLightMode();

  const selected = options.find((option) => (isDarkModeOn ? option.value === "dark" : option.value === "light"));

  return (
    <Select
      id="select-dark-mode"
      label="Select Mode"
      isClearable={false}
      options={options}
      onChange={switchDarkMode}
      value={selected}
    />
  );
}
