import { LoadingScreen } from "components/common/LoadingScreen";
import type { AuthenticationContextValue } from "components/providers/AuthenticationProvider";
import { useAuthentication } from "components/providers/AuthenticationProvider";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { NotFoundRoute } from "routes/NotFoundRoute";

export interface ProtectedRouteProps {
  children: ReactNode;
  // The condition callback to check access
  condition: (auth: AuthenticationContextValue) => boolean;
  // The component to render as a fallback in case of access denied.
  fallback?: ReactNode;
  loading?: ReactNode;
}

// TODO: not yet used
export function ProtectedRoute({
  children,
  condition,
  loading = <LoadingScreen />,
  fallback = <NotFoundRoute />,
}: ProtectedRouteProps) {
  const auth = useAuthentication();
  const allowed = useMemo(() => condition(auth), [auth, condition]);

  if (auth.loading) {
    return <>{loading}</>;
  }

  if (!allowed) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}
