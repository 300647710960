import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export function RedemptionQueueToolNavigation() {
  const { id: vaultId } = useParams<"id">();
  const routeTo = useMemo(() => `/tools/redemption-queue/vault/${vaultId}`, [vaultId]);

  return (
    <nav aria-label="RedemptionQueueTool">
      <Navigation direction="horizontal" className="lg:border-base-200 lg:flex-nowrap lg:justify-between lg:border-b-2">
        <RedemptionQueueToolNavigationItem to={`${routeTo}/requests`}>Requests</RedemptionQueueToolNavigationItem>
        <RedemptionQueueToolNavigationItem to={`${routeTo}/managers`}>Managers</RedemptionQueueToolNavigationItem>
        <RedemptionQueueToolNavigationItem to={`${routeTo}/configuration`}>
          Configuration
        </RedemptionQueueToolNavigationItem>
      </Navigation>
    </nav>
  );
}

type RedemptionQueueToolNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function RedemptionQueueToolNavigationItem(props: RedemptionQueueToolNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} className="text-center lg:w-full" />;
}
