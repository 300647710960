import type { Address } from "@enzymefinance/environment";
import type { VaultDetailsFragment } from "queries/core";

import { toAddress } from "@enzymefinance/environment";
import { DepositSidebarItem } from "./DepositSidebarItem";
import { MyDepositSidebar } from "./MyDepositSidebar.js";
import type { Vault } from "./MyVaultsMenu";
import { TraderSidebar } from "./TraderSidebar";
import { WithdrawSidebarItem } from "./WithdrawSidebarItem";

interface VaultIsCurrentProps {
  selectedVault?: Vault;
  signerAddress?: Address;
  vault?: VaultDetailsFragment | null;
}

export function VaultIsCurrent({ vault, signerAddress, selectedVault }: VaultIsCurrentProps) {
  return (
    <>
      {selectedVault?.depositor ? <MyDepositSidebar id={toAddress(selectedVault.id)} /> : null}
      {vault ? <DepositSidebarItem signerAddress={signerAddress} vault={vault} /> : null}
      {vault ? <WithdrawSidebarItem signerAddress={signerAddress} vault={vault} selectedVault={selectedVault} /> : null}
      {selectedVault?.manager || selectedVault?.trader ? <TraderSidebar id={selectedVault.id} /> : null}
    </>
  );
}
